import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Chart from "chart.js/auto";
import { toast } from "react-toastify";
import { CategoryScale } from "chart.js";
import LineChart from "../components/LineChart";

import Loader from "../components/Loader";
import Message from "../components/Message";
import HorizontalBarChart from "../components/BarChart";
import { getCurrentDate, getDateOfLast } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";
import { useGetUsersDashobaordQuery } from "../slices/usersSlice";
import { useGetVisitorsDashboardQuery } from "../slices/visitorsSlice";
import { useGetAttendanceDashboardQuery } from "../slices/attendanceSlice";

Chart.register(CategoryScale);

const DashBoard = () => {
  const offset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
  const { userInfo } = useSelector((state) => state.auth);
  const [selectChart, setSelectChart] = useState("Hourly Attendance");
  const [startDate, setStartDate] = useState(getDateOfLast(30));
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [invalidDateInputs, setInvalidDateInputs] = useState(false);

  let startDate1 =
    new Date(`${getCurrentDate()}T00:00:00.567Z`).getTime() - offset;

  let endDate1 =
    new Date(`${getCurrentDate()}T23:59:59.567Z`).getTime() - offset;

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate1}&timeIn[lte]=${endDate1}`
  );

  const [getUsersQuery, setGetUsersQuery] = useState(
    `?gymId=${userInfo.gymId._id}&joinedDate[gte]=${startDate}&joinedDate[lte]=${endDate}`
  );

  const [getVisitorsQuery, setGetVisitorsQuery] = useState(
    `?gymId=${userInfo.gymId._id}&visitedDate[gte]=${startDate}&visitedDate[lte]=${endDate}`
  );

  const dispatch = useDispatch();

  const {
    data: attendanceList,
    isLoading,
    error,
    refetch,
  } = useGetAttendanceDashboardQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const { data: users, isUsersLoading } = useGetUsersDashobaordQuery({
    query: getUsersQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const { data: visitors, isVisitorsLoading } = useGetVisitorsDashboardQuery({
    query: getVisitorsQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const chartLabels = [
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  const [barObjectForPeriod, setBarObjectForPeriod] = useState({
    labels: ["X", "Y", "X"],
    datasets: [
      {
        label: "Number of days",
        data: [0, 0, 0],
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,0.5)",
        borderWidth: 1,
        barThickness: 10,
        borderRadius: 8,
      },
    ],
  });

  const dayLabels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [chartObjectForDays, setChartObjectForDays] = useState({
    labels: dayLabels,
    datasets: [
      {
        label: "Day",
        data: [0, 0, 0, 0, 0, 0, 0],
        fill: true,
        borderColor: "rgb(100, 230, 100)",
      },
    ],
  });

  const [chartObjectForUsersJoined, setChartObjectForUsersJoined] = useState({
    labels: [0, 0, 0, 0],
    datasets: [
      {
        label: "Month",
        data: [0, 0, 0, 0, 0],
        fill: true,
        borderColor: "rgb(100, 230, 100)",
      },
    ],
  });

  const [chartObjectForVisitors, setChartObjectForVisitors] = useState({
    labels: [0, 0, 0, 0],
    datasets: [
      {
        label: "Month",
        data: [0, 0, 0, 0, 0],
        fill: true,
        borderColor: "rgb(100, 230, 100)",
      },
    ],
  });

  const [chartObjectForFootfall, setChartObjectForFootfall] = useState({
    labels: [0, 0, 0, 0],
    datasets: [
      {
        label: "Month",
        data: [0, 0, 0, 0, 0],
        fill: true,
        borderColor: "rgb(100, 230, 100)",
      },
    ],
  });

  // const dispatch = useDispatch();
  let chartDataForPeriod;
  let dataForChartForPeriod;

  // useEffect(() => {
  //   refetch();
  //   if (attendanceList) dispatch(setAttendances(attendanceList));
  // }, [refetch, attendanceList, dispatch]);

  const getDataForChart = (attendanceOnGivenDuration) => {
    attendanceOnGivenDuration = attendanceOnGivenDuration?.map((att) =>
      new Date(att.timeIn).getHours()
    );

    let countUsersPerHour = {};
    attendanceOnGivenDuration?.forEach((element) => {
      countUsersPerHour[element] = (countUsersPerHour[element] || 0) + 1;
    });

    let dataForChart = chartLabels?.map((l) =>
      countUsersPerHour[l] ? countUsersPerHour[l] : 0
    );
    return dataForChart;
  };

  const getAttendanceForPeriod = () => {
    let attendanceData = isLoading ? [] : attendanceList ? attendanceList : [];
    return attendanceData;
  };

  useEffect(() => {
    if (startDate > getCurrentDate()) {
      setInvalidDateInputs(true);
      toast.error("Start date can not be greater than today");
    } else if (startDate > endDate) {
      setInvalidDateInputs(true);
      toast.error("Start date can not be greater than End date");
    } else {
      setInvalidDateInputs(false);
      startDate1 = new Date(`${startDate}T00:00:00.567Z`).getTime() - offset;
      endDate1 = new Date(`${endDate}T23:59:59.567Z`).getTime() - offset;

      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate1}&timeIn[lte]=${endDate1}`
      );
      setGetUsersQuery(
        `?gymId=${userInfo.gymId._id}&joinedDate[gte]=${startDate}&joinedDate[lte]=${endDate}`
      );
      setGetVisitorsQuery(
        `?gymId=${userInfo.gymId._id}&visitedDate[gte]=${startDate}&visitedDate[lte]=${endDate}`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          dashboardStartDate: startDate,
          dashboardEndDate: endDate,
        })
      );
    }
  }, [startDate, endDate, userInfo.gymId._id]);

  useEffect(() => {
    refetch();
  }, [getApiQuery, refetch]);
  const initialAttendanceOnGivenPeriod = getAttendanceForPeriod();
  const initialDataForChartForPeriod = getDataForChart(
    initialAttendanceOnGivenPeriod
  );

  const [chartObjectForPeriod, setChartObjectForPeriod] = useState({
    labels: chartLabels,
    datasets: [
      {
        // label: "Hour",
        data: initialDataForChartForPeriod,
        fill: true,
        borderColor: "rgb(100, 230, 100)",
      },
    ],
  });

  const loadHourlyData = () => {
    let attendanceOnGivenPeriod = getAttendanceForPeriod();
    dataForChartForPeriod =
      attendanceOnGivenPeriod.length > 0 && attendanceOnGivenPeriod[0];
    chartDataForPeriod = {
      labels: chartLabels,
      datasets: [
        {
          // label: "Hour",
          data: dataForChartForPeriod,
          fill: true,
          borderColor: "rgb(0, 200, 40)",
        },
      ],
    };
    setChartObjectForPeriod(chartDataForPeriod);
  };

  const loadDataForBarChart = () => {
    const attData = getAttendanceForPeriod();
    const dataForBarChart = attData.length > 0 && attData[2];
    let sortedUsers = Object.keys(dataForBarChart).sort(function (a, b) {
      return dataForBarChart[b] - dataForBarChart[a];
    });
    sortedUsers.length = 10;
    const sortedLabels = sortedUsers?.map((usr) => usr?.split("_")[1]);

    const userData = sortedUsers?.map((ur) => dataForBarChart[ur]);
    setBarObjectForPeriod({
      labels: sortedLabels,
      datasets: [
        {
          label: "Number of days",
          data: userData,
          backgroundColor: "rgba(75,192,192,1)",
          borderColor: "rgba(75,192,192,0.5)",
          borderWidth: 1,
          barThickness: 20,
        },
      ],
    });
  };

  const loadDayWiseData = () => {
    const attData = getAttendanceForPeriod();
    const dataForDayWise = attData.length > 0 && attData[1];
    const dayWiseData = [
      dataForDayWise[0] ? dataForDayWise[0] : 0,
      dataForDayWise[1] ? dataForDayWise[1] : 0,
      dataForDayWise[2] ? dataForDayWise[2] : 0,
      dataForDayWise[3] ? dataForDayWise[3] : 0,
      dataForDayWise[4] ? dataForDayWise[4] : 0,
      dataForDayWise[5] ? dataForDayWise[5] : 0,
      dataForDayWise[6] ? dataForDayWise[6] : 0,
    ];
    const chartDataForDayWise = {
      labels: dayLabels,
      datasets: [
        {
          // label: "Hour",
          data: dayWiseData,
          fill: true,
          borderColor: "rgb(0, 200, 40)",
        },
      ],
    };
    setChartObjectForDays(chartDataForDayWise);
  };

  const getJoinedUsers = async () => {
    let usersData = isUsersLoading ? [] : users;
    return usersData;
  };

  const loadUsersJoined = async () => {
    const usersData = await getJoinedUsers();
    let usersJoined = usersData;
    const chartDataForUsers = {
      labels: usersJoined?.map((u) => u.monthYY),
      datasets: [
        {
          // label: "Hour",
          data: usersJoined?.map((u) => u.count),
          fill: true,
          borderColor: "rgb(0, 200, 40)",
        },
      ],
    };
    setChartObjectForUsersJoined(chartDataForUsers);
  };

  const getVisitors = async () => {
    let usersData = isVisitorsLoading ? [] : visitors;
    return usersData;
  };

  const loadVisitors = async () => {
    const visitorsData = await getVisitors();
    let monthlyVisitors = visitorsData;
    const chartDataForVisitors = {
      labels: monthlyVisitors?.map((u) => u.monthYY),
      datasets: [
        {
          // label: "Hour",
          data: monthlyVisitors?.map((u) => u.count),
          fill: true,
          borderColor: "rgb(0, 200, 40)",
        },
      ],
    };
    setChartObjectForVisitors(chartDataForVisitors);
  };

  const loadFootfall = async () => {
    const attendance = await getAttendanceForPeriod();
    let monthlyFootfall = attendance[3];
    const chartDataForAttendance = {
      labels: monthlyFootfall?.map((u) => u.monthYY),
      datasets: [
        {
          // label: "Hour",
          data: monthlyFootfall?.map((u) => u.count),
          fill: true,
          borderColor: "rgb(0, 200, 40)",
        },
      ],
    };
    setChartObjectForFootfall(chartDataForAttendance);
  };

  return (
    <>
      <h6> Dashboard </h6>
      {userInfo.gymId.subscriptionType !== "Premium" ? (
        <>
          <Row
            style={{
              width: "95%",
              margin: "auto",
              paddingBottom: "1%",
            }}
          >
            <Button
              className="marginPointOne sixteenWidth buttonOrange numbersButtonDashboard"
              value="Hourly Attendance"
              onClick={(e) => setSelectChart(e.target.value)}
            >
              Hourly
            </Button>{" "}
            <Button
              className="marginPointOne sixteenWidth buttonPeach numbersButtonDashboard"
              value="Day Wise Attendance"
              onClick={(e) => setSelectChart(e.target.value)}
            >
              Day Wise
            </Button>{" "}
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButtonDashboard"
              value="Top Attendees"
              onClick={(e) => setSelectChart(e.target.value)}
            >
              Top 10
            </Button>{" "}
            <Button
              className="marginPointOne sixteenWidth buttonOrchid numbersButtonDashboard"
              value="Users Joined"
              onClick={(e) => setSelectChart(e.target.value)}
            >
              Users Joined
            </Button>{" "}
            <Button
              className="marginPointOne sixteenWidth buttonGreen numbersButtonDashboard"
              value="Visitors"
              onClick={(e) => setSelectChart(e.target.value)}
            >
              Visitors
            </Button>{" "}
            <Button
              className="marginPointOne sixteenWidth buttonReddish numbersButtonDashboard"
              value="Footfall"
              onClick={(e) => setSelectChart(e.target.value)}
            >
              Footfall
            </Button>{" "}
          </Row>
          <Row
            style={{
              width: "90%",
              margin: "auto",
              paddingBottom: "1%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-23"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>{" "}
              </Form.Group>
            </Col>
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-27"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>{" "}
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md="auto"
              sm={6}
              className="d-flex justify-content-center"
            >
              <Form.Group as={Row} className="my-2" controlId="visitorMobile">
                <Button
                  className={`${userInfo.themeColor}ThemeHeaderTop`}
                  disabled={invalidDateInputs}
                  onClick={
                    selectChart === "Top Attendees"
                      ? loadDataForBarChart
                      : selectChart === "Day Wise Attendance"
                      ? loadDayWiseData
                      : selectChart === "Users Joined"
                      ? loadUsersJoined
                      : selectChart === "Visitors"
                      ? loadVisitors
                      : selectChart === "Footfall"
                      ? loadFootfall
                      : loadHourlyData
                  }
                >
                  Get {selectChart} Data
                </Button>{" "}
              </Form.Group>
            </Col>
          </Row>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message>{error?.data?.error}</Message>
          ) : (
            <div>
              <Row>
                {selectChart === "Top Attendees" ? (
                  <HorizontalBarChart
                    chartData={{
                      ...barObjectForPeriod,
                      labelText: `X-axis represents total number of days User came to gym, Y-axis represent User names...${selectChart} from ${startDate} to ${endDate}`,
                    }}
                  />
                ) : selectChart === "Day Wise Attendance" ? (
                  <LineChart
                    chartData={{
                      ...chartObjectForDays,
                      labelText: `X-axis represents Days, Y-axis represents total number of users came to gym on that Day...${selectChart} from ${startDate} to ${endDate}`,
                    }}
                  />
                ) : selectChart === "Users Joined" ? (
                  <LineChart
                    chartData={{
                      ...chartObjectForUsersJoined,
                      labelText: `X-axis represents Months, Y-axis represents number of users joined on that month...${selectChart} from ${startDate} to ${endDate}`,
                    }}
                  />
                ) : selectChart === "Visitors" ? (
                  <LineChart
                    chartData={{
                      ...chartObjectForVisitors,
                      labelText: `X-axis represents Months, Y-axis represents number of Visitors for that month...${selectChart} from ${startDate} to ${endDate}`,
                    }}
                  />
                ) : selectChart === "Footfall" ? (
                  <LineChart
                    chartData={{
                      ...chartObjectForFootfall,
                      labelText: `X-axis represents Months, Y-axis represents number of Users came to gym for that month...${selectChart} from ${startDate} to ${endDate}`,
                    }}
                  />
                ) : (
                  <LineChart
                    chartData={{
                      ...chartObjectForPeriod,
                      labelText: `X-axis represents hours, Y-axis represents total number of users came to gym on that hour...${selectChart} from ${startDate} to ${endDate}`,
                    }}
                  />
                )}
              </Row>
            </div>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <label style={{ color: "red" }}>
            {" "}
            This feature is available only for Premium Plus.
          </label>
          <br></br> <br></br>
          <h5>
            {" "}
            Please upgrade to Premium Plus to get Analytics and Dashboard of
            your Gym activity.
          </h5>{" "}
          <h6>
            This Dashboard can give you an idea about your gym traffic, enabling
            you to make better plans for your gym.
          </h6>
        </div>
      )}
    </>
  );
};
export default DashBoard;

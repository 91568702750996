import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPtUsersQuery } from "../slices/ptUsersSlice";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getCurrentDate, getDateOfLast } from "../utils/getCurrentDate";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setCredentials } from "../slices/authSlice";

const PtUsersScreen = () => {
  const { id: userId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const [selectedLanguage, setSelectedLanguage] = useState(
    userInfo.preferredLanguage ? userInfo.preferredLanguage : "English"
  );
  const [startDate, setStartDate] = useState(getDateOfLast(7));
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [usersToList, setUsersToList] = useState([]);
  const dispatch = useDispatch();
  const [totalPtAmount, setTotalPtAmount] = useState(0);
  const [totalPtPaidAmount, setTotalPtPaidAmount] = useState(0);

  const [getPtApiQuery, setGetPtApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&ptStartDate[gte]=${startDate}T00:00:00.000Z&ptStartDate[lte]=${endDate}T23:59:59.000Z`
  );

  const extractMemberId = (compositeId) => {
    return compositeId.slice(4); // Removes the first 4 characters (gymId)
  };

  const {
    data: ptUsers,
    isLoading: ptUsersLoading,
    error: ptUsersError,
    refetch,
  } = useGetPtUsersQuery({
    query: getPtApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  useEffect(() => {
    if (userInfo) {
      refetch();
    }
  }, [startDate, endDate, refetch, userInfo]);

  useEffect(() => {
    if (ptUsers) {
      setUsersToList(ptUsers);

      const calculateTotalPtAmount = () => {
        const total = ptUsers
          .map((user) => user.ptAmount || 0)
          .reduce((acc, amount) => acc + amount, 0);
        setTotalPtAmount(total);
      };
      const calculateTotalPtPaidAmount = () => {
        const total = ptUsers
          .map((user) => user.paidAmount || 0)
          .reduce((acc, amount) => acc + amount, 0);
        setTotalPtPaidAmount(total);
      };
      calculateTotalPtAmount();
      calculateTotalPtPaidAmount();
      //   console.log("totalSales : ", totalPtAmount);
      //   console.log("collected :", totalPtPaidAmount);
    }
  }, [ptUsers]);

  const handleGetUser = () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      // if (endDate?.split("T")[0] > getCurrentDate())
      //   setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());

      setGetPtApiQuery(
        `?gymId=${userInfo.gymId._id}&ptStartDate[gte]=${startDate}T00:00:00.000Z&ptStartDate[lte]=${endDate}T23:59:59.000Z`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          ptStartDate: startDate,
          ptEndDate: endDate,
        })
      );
    }
  };

  return (
    <>
      <h4>PT Members</h4>
      <Row
        className="my-1"
        style={{
          width: "90%",
          margin: "auto",
          paddingBottom: "0.5%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="startDate">
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="endDate">
            <Form.Control
              type="date"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            onClick={() => handleGetUser()}
          >
            Get PT Users
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button className="marginPointOne fifteenWidth buttonGreen numbersButtonDashboard">
          Total {usersToList.length}
        </Button>
        <Button className="marginPointOne sixteenWidth buttonOrange numbersButtonDashboard">
          Total Sales {totalPtAmount}
        </Button>
        <Button className="marginPointOne sixteenWidth buttonTeal numbersButtonDashboard">
          Collected {totalPtPaidAmount}
        </Button>
      </Row>
      <br />
      {ptUsersLoading ? (
        <Loader />
      ) : ptUsersError ? (
        <Message>{ptUsersError?.data?.error}</Message>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>From</th>
                  <th>To</th>
                  <th>PT Amount</th>
                  <th>Paid</th>
                  <th>Paid Date</th>
                  <th>Payment Mode</th>
                  <th>Pending</th>
                  <th>Trainer</th>
                </tr>
              </thead>
              {usersToList.length > 0 ? (
                <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                  {usersToList.map((ptUsers) => (
                    <tr key={ptUsers?.userId}>
                      <td>
                        <Link
                          to={`/users/${extractMemberId(
                            ptUsers.compositeId?._id
                          )}`}
                        >
                          {ptUsers?.compositeId?.firstName}
                        </Link>
                      </td>
                      <td>{extractMemberId(ptUsers.compositeId?._id)}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {ptUsers.ptStartDate?.split("T")[0]}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {ptUsers.ptEndDate?.split("T")[0]}
                      </td>
                      <td>{ptUsers.ptAmount}</td>
                      <td>{ptUsers.paidAmount}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {ptUsers.paidDate?.split("T")[0]}
                      </td>
                      <td>{ptUsers.transactionId}</td>
                      <td>{ptUsers.pendingAmount}</td>
                      <td>{ptUsers.trainerName}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Message>No PT Users Available for the selected Period</Message>
              )}
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default PtUsersScreen;

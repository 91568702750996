import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { toast } from "react-toastify";
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  FaUser,
  FaRegListAlt,
  FaUsers,
  FaUsersSlash,
  FaMoneyBillAlt,
  FaUserPlus,
  FaClipboardList,
  FaChartLine,
  FaWhatsappSquare,
  FaPhoneAlt,
  FaWhatsapp,
  FaUserCircle,
  FaRegEdit,
  FaInfoCircle,
} from "react-icons/fa"; // Add the icons you need
import { FaClock } from "react-icons/fa6";
import {
  RiPassPendingFill,
  RiFolderUploadFill,
  RiQrCodeFill,
  RiBuilding4Fill,
  RiLogoutCircleRLine,
  RiBodyScanFill,
} from "react-icons/ri";
import { CgGym, CgProfile, CgMenuBoxed } from "react-icons/cg";
import { MdAccountBalance, MdQrCode2, MdManageAccounts } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";

import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../slices/gymSlice";
import { logout } from "../slices/authSlice";
import { removeUsers } from "../slices/userSlice";

import { removeAttendances } from "../slices/attendanceListSlice";
import { removeSelfUsers } from "../slices/userSelfSlice";
import preferredLanguage from "../assets/preferredLanguage.json";
import { getCurrentDate } from "../utils/getCurrentDate";
import { getTodaysVisitors, getTodaysExpiredUsers } from "../utils/schedulars";
import { useEffect, useState } from "react";

const HeaderLeft = function ({ leftMenuOpen, setExpandMenu }) {
  const { userInfo } = useSelector((state) => state.auth);

  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApi] = useLogoutMutation();
  let gymId;
  if (userInfo) {
    gymId = userInfo.gymId._id;
  }
  const handleLogout = async () => {
    try {
      await logoutApi().unwrap();
      dispatch(logout());
      dispatch(removeUsers());
      dispatch(removeAttendances());
      dispatch(removeSelfUsers());
      navigate("/login");
      toast.success("Successfully logged out");
    } catch (err) {
      console.log(err);
    }
  };
  const [visitorsReminder, setVisitorsReminder] = useState(false);
  const [expiredUsersReminder, setExpiredUsersReminder] = useState(false);
  useEffect(() => {
    const interval = setInterval(async () => {
      console.log("Inside interval: " + new Date());
      const now = new Date();

      // Get current time in IST
      const nowIST = new Date(
        now.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      );
      const currentHour = nowIST.getHours();

      if (
        (currentHour >= 8 && currentHour < 11) ||
        (currentHour >= 17 && currentHour < 20)
      ) {
        if (userInfo?.token && !visitorsReminder) {
          const vst = await getTodaysVisitors(userInfo);
          if (vst?.length > 0) setVisitorsReminder(true);
        }

        if (userInfo?.token && !expiredUsersReminder) {
          const usrs = await getTodaysExpiredUsers(userInfo);
          if (usrs?.length > 0) setExpiredUsersReminder(true);
        }
      }
    }, 3600000); // Runs every 5 minutes

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [userInfo, visitorsReminder, expiredUsersReminder]);

  return (
    <header
      style={{
        position: "sticky",
        top: "0px",
        zIndex: 1,
      }}
      className={`${userInfo?.themeColor}ThemeHeaderTop`}
    >
      {userInfo ? (
        <>
          <Navbar
            style={{
              backgroundColor: "inherit", // Use any hex color code
              color: "#ffffff", // Set the text color
              height: "40px",
              marginTop: "0.2px",
            }}
          >
            <Container
              style={{
                backgroundColor: "inherit", // Use any hex color code
                color: "#ffffff", // Set the text color
                height: "40px",
                marginTop: "0.2px",
              }}
            >
              <LinkContainer to="/markAttendance">
                <Navbar.Brand>
                  {/* <img src={logo} alt="zpotter"></img>{" "} */}
                  {/* <label style={{ fontSize: "1.5rem" }}>ZPOTTER</label> */}
                  zpotter
                </Navbar.Brand>
              </LinkContainer>
              {new Date(getCurrentDate()) <= new Date("2024-12-13") && (
                <Nav.Link
                  style={{
                    color: "orange",
                    whiteSpace: "nowrap",
                    marginLeft: "7rem",
                    fontSize: "1.6rem",
                  }}
                  href="https://videos.zpotter.in/updateVideos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>Updated on 11-Dec-2024 : Click</strong>
                </Nav.Link>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <label style={{ fontSize: "1.5rem" }}>
                  {" "}
                  {userInfo ? userInfo?.gymId?.name : "zpotter"}
                </label>
                {visitorsReminder && (
                  <div style={{ margin: "0px 20px" }}>
                    <Link
                      to="/visitors"
                      className="twinkle-text"
                      style={{
                        color: "white",
                        color: "orange",
                      }}
                    >
                      Check Visitors
                    </Link>
                    <Link
                      onClick={() => setVisitorsReminder(false)}
                      style={{
                        color: "white",
                        margin: "0px 2px",
                        color: "red",
                      }}
                    >
                      <IoMdCloseCircleOutline />
                    </Link>
                  </div>
                )}
                {expiredUsersReminder && (
                  <div style={{ margin: "0px 20px" }}>
                    <Link
                      to="/users"
                      className="twinkle-text"
                      style={{
                        color: "white",
                        color: "yellow",
                      }}
                    >
                      Check Expiring Members
                    </Link>
                    <Link
                      onClick={() => setExpiredUsersReminder(false)}
                      style={{
                        color: "white",
                        margin: "0px 2px",
                        color: "red",
                      }}
                    >
                      <IoMdCloseCircleOutline />
                    </Link>
                  </div>
                )}
              </div>
              {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav"> */}
              <Nav
                className="ms-auto"
                style={{
                  backgroundColor: "inherit",
                  color: "#ffffff",
                }}
              >
                {userInfo ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "10",
                        width: "100%",
                      }}
                    >
                      <FaUserCircle style={{ marginRight: "3px" }} />
                    </div>

                    <NavDropdown
                      title={userInfo.firstName}
                      style={{ marginRight: "90px" }}
                    >
                      <LinkContainer to="/profile">
                        <NavDropdown.Item>
                          {" "}
                          <CgProfile style={{ marginRight: "3px" }} />
                          {preferredLanguage[selectedLanguage]?.myProfile}
                        </NavDropdown.Item>
                      </LinkContainer>
                      {userInfo.role === "owner" ||
                      userInfo.role === "Super Admin" ? (
                        <>
                          <LinkContainer to="/gymProfile">
                            <NavDropdown.Item>
                              {" "}
                              <RiBuilding4Fill style={{ marginRight: "3px" }} />
                              {preferredLanguage[selectedLanguage]?.gymProfile}
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/updateAttendancePageScreen">
                            <NavDropdown.Item>
                              <FaRegEdit style={{ marginRight: "3px" }} />
                              {preferredLanguage[selectedLanguage]?.editAttPage}
                            </NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/packages">
                            <NavDropdown.Item>
                              <CgMenuBoxed style={{ marginRight: "2px" }} />
                              Packages
                            </NavDropdown.Item>
                          </LinkContainer>
                          {userInfo.role === "owner" ||
                          userInfo.role === "Super Admin" ||
                          userInfo.role === "Admin" ? (
                            <LinkContainer to="/dashboard">
                              <NavDropdown.Item>
                                <FaChartLine style={{ marginRight: "2px" }} />
                                {preferredLanguage[selectedLanguage]?.analytics}
                              </NavDropdown.Item>
                            </LinkContainer>
                          ) : (
                            <></>
                          )}
                          <LinkContainer to="/promotionalMessage">
                            <NavDropdown.Item>
                              {" "}
                              <FaWhatsapp style={{ marginRight: "2px" }} />
                              {preferredLanguage[selectedLanguage]?.sendAds}
                            </NavDropdown.Item>
                          </LinkContainer>
                        </>
                      ) : (
                        <></>
                      )}

                      <LinkContainer to="/whatsAppMessages">
                        <NavDropdown.Item>
                          <FaWhatsappSquare style={{ marginRight: "2px" }} />
                          {preferredLanguage[selectedLanguage]?.wsNotification}
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/qrCode">
                        <NavDropdown.Item>
                          <MdQrCode2 style={{ marginRight: "2px" }} />
                          {
                            preferredLanguage[selectedLanguage]
                              ?.attendanceQrCode
                          }
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/qrcodeRegister">
                        <NavDropdown.Item>
                          <RiQrCodeFill style={{ marginRight: "2px" }} />
                          {preferredLanguage[selectedLanguage]?.regQrCode}
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/showToVisitors">
                        <NavDropdown.Item>
                          <FaInfoCircle style={{ marginRight: "2px" }} />
                          Show to Visitors
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/contactUs">
                        <NavDropdown.Item>
                          {" "}
                          <FaPhoneAlt style={{ marginRight: "2px" }} />
                          Contact Us
                        </NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Item onClick={handleLogout}>
                        <RiLogoutCircleRLine style={{ marginRight: "3px" }} />
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link>
                      <FaUser />
                      Login
                    </Nav.Link>
                  </LinkContainer>
                )}
              </Nav>
              {/* </Navbar.Collapse> */}
            </Container>
          </Navbar>
          {leftMenuOpen ? (
            <aside
              className={`${userInfo.themeColor}ThemeHeaderLeft`}
              style={{
                width: leftMenuOpen ? "270px" : "45px", // Adjust width as needed
                // display: "none",
                height: "100vh", // Full viewport height
                position: "fixed", // Keep it fixed on the left
                top: "40px",
                left: "0",

                padding: "20px",
                overflowY: "auto", // Allow scrolling for long content
              }}
            >
              <div>
                {leftMenuOpen && (
                  <button
                    style={{
                      backgroundColor: "orange",
                      border: "none",
                      padding: "10px",
                      color: "white",
                      cursor: "pointer",
                      borderRadius: "5px",
                      // Align the button to the right
                      marginBottom: "3px", // Add some spacing
                    }}
                    onClick={(e) => setExpandMenu(false)}
                  >
                    <MdKeyboardDoubleArrowLeft style={{ marginRight: "2px" }} />{" "}
                    Hide Menu
                  </button>
                )}
              </div>
              <Navbar
                style={{
                  backgroundColor: "inherit", // Use any hex color code
                  color: "#ffffff", // Set the text color
                  marginTop: "0.2px",
                  height: "100%",
                  flexDirection: "column",
                }}
                expand="md"
                collapseOnSelect
              >
                <Container
                  style={{
                    backgroundColor: "inherit", // Use any hex color code
                    color: "#ffffff", // Set the text color
                    marginTop: "0.2px",
                  }}
                >
                  {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav"> */}
                  <Nav
                    className="flex-column"
                    style={{
                      backgroundColor: "inherit",
                      color: "#ffffff",
                      alignItems: "left",
                    }}
                  >
                    <Nav.Link
                      href="https://mytime.zpotter.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaClock style={{ marginRight: "2px" }} />
                      {preferredLanguage[selectedLanguage]?.attendance}
                    </Nav.Link>
                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ? (
                      <LinkContainer to="/trends">
                        <Nav.Link>
                          {" "}
                          <FaUserPlus style={{ marginRight: "2px" }} />
                          {
                            preferredLanguage[selectedLanguage]?.attendancePage
                              .todaysTrend
                          }
                        </Nav.Link>
                      </LinkContainer>
                    ) : (
                      <></>
                    )}

                    <LinkContainer to="/userRegistration">
                      <Nav.Link>
                        {" "}
                        <FaUserPlus style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.userReg}
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/attendanceList">
                      <Nav.Link>
                        {" "}
                        <FaClipboardList style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.attendanceList}
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/visitors">
                      <Nav.Link>
                        {" "}
                        <FaRegListAlt style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.visitors}
                      </Nav.Link>
                    </LinkContainer>
                    <hr style={{ border: "1px solid #ccc", margin: "5px 0" }} />
                    <LinkContainer to="/allUsers">
                      <Nav.Link>
                        <FaUsers style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.allMembers}
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/users">
                      <Nav.Link>
                        <FaUsers style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.activeMember}
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/subsExpiredUsers">
                      <Nav.Link>
                        <FaUsersSlash style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.inActiveMembers}
                      </Nav.Link>
                    </LinkContainer>
                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ? (
                      <>
                        <LinkContainer to="/subscriptions">
                          <Nav.Link>
                            <FaMoneyBillAlt style={{ marginRight: "2px" }} />
                            {preferredLanguage[selectedLanguage]?.subscriptions}
                          </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/ptUsers">
                          <Nav.Link>
                            <RiBodyScanFill style={{ marginRight: "2px" }} />
                            PT Members
                          </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/payments">
                          <Nav.Link>
                            <FaMoneyBillAlt style={{ marginRight: "2px" }} />
                            {preferredLanguage[selectedLanguage]?.paymentsList}
                          </Nav.Link>
                        </LinkContainer>
                      </>
                    ) : (
                      <></>
                    )}

                    <LinkContainer to="/regPendingUsers">
                      <Nav.Link>
                        <RiPassPendingFill style={{ marginRight: "2px" }} />
                        {preferredLanguage[selectedLanguage]?.pendingReg}
                      </Nav.Link>
                    </LinkContainer>
                    {userInfo.role === "owner" ||
                    userInfo.role === "Super Admin" ||
                    userInfo.role === "Admin" ? (
                      <>
                        {" "}
                        <LinkContainer to="/uploadUsers">
                          <Nav.Link>
                            <RiFolderUploadFill
                              style={{ marginRight: "2px" }}
                            />
                            {preferredLanguage[selectedLanguage]?.bulkUpload}
                          </Nav.Link>
                        </LinkContainer>
                      </>
                    ) : (
                      ""
                    )}
                    <hr style={{ border: "1px solid #ccc", margin: "5px 0" }} />
                    {userInfo ? (
                      <>
                        {" "}
                        {userInfo.role === "owner" ||
                        userInfo.role === "Super Admin" ||
                        userInfo.role === "Admin" ? (
                          <LinkContainer to="/equipments">
                            <Nav.Link>
                              {" "}
                              <CgGym style={{ marginRight: "2px" }} />
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.equipmentManagement
                              }
                            </Nav.Link>
                          </LinkContainer>
                        ) : (
                          <></>
                        )}
                        {userInfo.role === "owner" ||
                        userInfo.role === "Super Admin" ? (
                          <>
                            {gymId ? (
                              <LinkContainer to="/accounts">
                                <Nav.Link>
                                  {" "}
                                  <MdAccountBalance
                                    style={{ marginRight: "2px" }}
                                  />
                                  {
                                    preferredLanguage[selectedLanguage]
                                      ?.accounts
                                  }
                                </Nav.Link>
                              </LinkContainer>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        <LinkContainer to="/associatesAttendance">
                          <Nav.Link>
                            {" "}
                            <MdManageAccounts style={{ marginRight: "2px" }} />
                            {
                              preferredLanguage[selectedLanguage]
                                ?.associateAttendanceList
                            }
                          </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/associates">
                          <Nav.Link>
                            {" "}
                            <MdManageAccounts style={{ marginRight: "2px" }} />
                            {
                              preferredLanguage[selectedLanguage]
                                ?.adminManagement
                            }
                          </Nav.Link>
                        </LinkContainer>
                        {/* {userInfo.role === "owner" ||
                        userInfo.role === "Super Admin" ||
                        userInfo.role === "Admin" ? (
                          <LinkContainer to="/dashboard">
                            <Nav.Link>
                              <FaChartLine style={{ marginRight: "2px" }} />
                              {preferredLanguage[selectedLanguage]?.analytics}
                            </Nav.Link>
                          </LinkContainer>
                        ) : (
                          <></>
                        )} */}
                        {/* <LinkContainer to="/usersAtGym/896041834487">
                      <NavDropdown.Item>Numbers to Users</NavDropdown.Item>
                    </LinkContainer> */}
                        <hr
                          style={{
                            border: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        />
                      </>
                    ) : (
                      <LinkContainer to="/login">
                        <Nav.Link>
                          <FaUser />
                          {preferredLanguage[selectedLanguage]?.login}
                        </Nav.Link>
                      </LinkContainer>
                    )}
                  </Nav>
                  {/* </Navbar.Collapse> */}
                </Container>
              </Navbar>
            </aside>
          ) : (
            <aside
              className={`${userInfo.themeColor}ThemeHeaderLeft`}
              style={{
                width: leftMenuOpen ? "250px" : "45px", // Adjust width as needed
                // display: "none",
                height: "100vh", // Full viewport height
                position: "fixed", // Keep it fixed on the left
                top: "40px",
                left: "0",

                padding: "4px",
                overflowY: "auto", // Allow scrolling for long content
              }}
            >
              <div>
                {!leftMenuOpen && (
                  <button
                    style={{
                      backgroundColor: "orange",
                      border: "none",
                      padding: "10px",
                      color: "white",
                      cursor: "pointer",
                      // Align the button to the right
                      marginBottom: "3px", // Add some spacing
                    }}
                    onClick={(e) => setExpandMenu(true)}
                  >
                    <GiHamburgerMenu style={{ marginRight: "2px" }} />
                  </button>
                )}
              </div>
            </aside>
          )}
        </>
      ) : (
        <></>
      )}
    </header>
  );
};

export default HeaderLeft;

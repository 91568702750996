import { Button, Form, Row, Col } from "react-bootstrap";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import RegistrationContainer from "../components/RegistrationContainer";
import { useCreateUsersMutation } from "../slices/usersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";
import { useDeleteUserSelfMutation } from "../slices/usersSelfSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import preferredLanguage from "../assets/preferredLanguage.json";
import { useGetMyGymQuery } from "../slices/gymSlice";

const UserSelfProfileScreen = ({ user, setApproveEnabled }) => {
  const { userInfo } = useSelector((state) => state.auth);
  // const { data: user, isLoading } = useGetUserSelfQuery({
  //   userId,
  //   token: userInfo.token,
  // });

  const [userId, setUserId] = useState();
  const [referenceId, setReferenceId] = useState(user.userId);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [gender, setGender] = useState(user.gender);
  const [mobileNumber, setMobileNumber] = useState(user.mobileNumber);
  const [email, setEmail] = useState(user.email);
  const [aadharNumber, setAadharNumber] = useState(user.aadharNumber);
  const [heigthInCm, setHeigthInCm] = useState(user.heigthInCm);
  const [weightInKg, setWeightInKg] = useState(user.weightInKg);
  //const [ptEnabled, setPtEnabled] = useState(false);
  const [packageType, setPackageType] = useState("Select Package");
  const [joinedDate, setJoinedDate] = useState(getCurrentDate());
  const [dateOfBirth, setDateOfBirth] = useState(user.dateOfBirth);
  const [lastPaidDate, setLastPaidDate] = useState(getCurrentDate());
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [lastPaidAmount, setLastPaidAmount] = useState();
  const [subscriptionType, setSubscriptionType] = useState(
    user.subscriptionType
  );
  const [gymGoal, setGymGoal] = useState(user.gymGoal);
  const [address, setAddress] = useState(user.address);
  const [txnId, setTxnId] = useState("");
  const [offerDetails, setOfferDetails] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const [extraDays, setExtraDays] = useState();
  const [admissionFee, setAdmissionFee] = useState(0);

  const [expiryDatePresent, setExpiryDatePresent] = useState(false);
  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState("");
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [heigthInCmPresent, setHeigthInCmPresent] = useState(false);
  const [weightInKgPresent, setWeightInKgPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);
  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  const [lastPaidDatePresent, setLastPaidDatePresent] = useState(false);
  const [subscriptionAmountPresent, setSubscriptionAmountPresent] =
    useState(false);
  const [lastPaidAmountPresent, setLastPaidAmountPresent] = useState(false);
  const [paidAmountGreaterThanSubsAmount, setPaidAmountGreaterThanSubsAmount] =
    useState(false);
  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState(false);
  const [gymGoalPresent, setGymGoalPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  const [txnIdPresent, setTxnIdPresent] = useState(false);

  const genderList = ["Male", "Female", "Other"];
  const subscriptionList = [
    "Select Months",
    "1 Month",
    "2 Months",
    "3 Months",
    "4 Months",
    "5 Months",
    "6 Months",
    "7 Months",
    "8 Months",
    "9 Months",
    "10 Months",
    "11 Months",
    "12 Months",
    "13 Months",
    "14 Months",
    "15 Months",
    "16 Months",
    "17 Months",
    "18 Months",
    "24 Months",
  ];
  const gymGoalList = [
    "Select Gym Goal",
    "Weight Loss and Fat Reduction",
    "Muscle Gain and Strength Building",
    "Body Building",
    "Weight Lifting",
    "To Improve Stamina",
    "Stay Fit",
    "Health and Wellness",
    "Sports Performance",
    "Injury Rehabilitation",
  ];

  let {
    data: myGymDetails,
    isLoading: myGymDetailsLoading,
    error,
    refetch,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  const [packageList, setPackageList] = useState();
  const [packageDetails, setPackageDetails] = useState();

  useEffect(() => {
    refetch();
    if (!myGymDetailsLoading && myGymDetails) {
      const customPackage = myGymDetails?.customPackage || {};
      // console.log("customPackage:", customPackage);
      setPackageDetails(customPackage);
      setPackageList(["Select Package", ...Object.keys(customPackage)]);
      setAdmissionFee(myGymDetails?.admissionFee);
    }
  }, [myGymDetailsLoading, myGymDetails, refetch]);

  const [createUser] = useCreateUsersMutation();
  const [deleteUserSelf] = useDeleteUserSelfMutation();

  // const userInfo = useSelector((state) => state.auth);

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate(redirect);
  //     }
  //   }, [userInfo, redirect, navigate]);

  useEffect(() => {
    if (firstName?.length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.length > 0) {
      setLastNamePresent(false);
    }

    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (mobileNumber)
      if (mobileNumber?.toString().length === 10 && mobileNumber > 0) {
        setMobileNumberPresent(false);
      }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setEmailPresent(false);
    }

    if (aadharNumber?.length === 12) {
      setAadharNumberPresent(false);
    }

    if (Number(heigthInCm) >= 80 && Number(heigthInCm) < 230) {
      setHeigthInCmPresent(false);
    }
    if (Number(weightInKg) >= 20 && Number(weightInKg) < 200) {
      setWeightInKgPresent(false);
    }

    if (Number(lastPaidAmount) >= 0 && Number(lastPaidAmount) < 99999) {
      setLastPaidAmountPresent(false);
    }

    if (
      Number(lastPaidAmount) <
      Number(subscriptionAmount) + Number(admissionFee)
    ) {
      setPaidAmountGreaterThanSubsAmount(false);
    }

    if (Number(subscriptionAmount) > 0 && Number(subscriptionAmount) < 99999) {
      setSubscriptionAmountPresent(false);
    }

    if (txnId?.length > 0) {
      setTxnIdPresent(false);
    }

    if (
      subscriptionType !== undefined &&
      subscriptionType !== "Select Months"
    ) {
      setSubscriptionTypePresent(false);
    }

    if (gymGoal !== "Select Gym Goal") {
      setGymGoalPresent(false);
    }
    if (address?.length > 0) {
      setAddressPresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }
    if (lastPaidDate <= getCurrentDate()) {
      setLastPaidDatePresent(false);
    }

    if (expiryDate > getCurrentDate()) {
      setExpiryDatePresent(false);
    }
  }, [
    firstName,
    lastName,
    gender,
    mobileNumber,
    email,
    aadharNumber,
    heigthInCm,
    weightInKg,
    // ptEnabled,
    dateOfBirth,
    lastPaidAmount,
    lastPaidDate,
    joinedDate,
    subscriptionAmount,
    txnId,
    subscriptionType,
    gymGoal,
    address,
  ]);

  useEffect(() => {
    if (joinedDate && subscriptionType && packageType === "Select Package") {
      const monthsLater = new Date(joinedDate);
      monthsLater.setMonth(
        monthsLater.getMonth() + parseInt(subscriptionType?.split(" ")[0])
      );
      monthsLater.setDate(monthsLater.getDate() - 1);
      setExpiryDate(monthsLater.toISOString().slice(0, 10));
    }
  }, [joinedDate, subscriptionType]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (firstName?.length < 1 || firstName?.length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (
      Number(lastPaidAmount) >
      Number(subscriptionAmount) + Number(admissionFee)
    ) {
      setPaidAmountGreaterThanSubsAmount(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }
    if (mobileNumber)
      if (mobileNumber.toString().length !== 10 || mobileNumber < 0) {
        setMobileNumberPresent(true);
        fieldValidationSuccess = false;
      }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email) || email?.length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }

    if (aadharNumber)
      if (aadharNumber?.length !== 12 || Number(aadharNumber) < 0) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }
    if (heigthInCm)
      if (Number(heigthInCm) < 80 || Number(heigthInCm) > 230) {
        setHeigthInCmPresent(true);
        fieldValidationSuccess = false;
      }
    if (weightInKg)
      if (Number(weightInKg) < 20 || Number(weightInKg) > 200) {
        setWeightInKgPresent(true);
        fieldValidationSuccess = false;
      }

    if (
      lastPaidAmount === null ||
      lastPaidAmount === undefined ||
      Number(lastPaidAmount) < 0 ||
      Number(lastPaidAmount) > 99999
    ) {
      setLastPaidAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (
      !subscriptionAmount ||
      Number(subscriptionAmount) <= 0 ||
      Number(subscriptionAmount) > 99999
    ) {
      setSubscriptionAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (txnId?.length > 40) {
      setTxnIdPresent(true);
      fieldValidationSuccess = false;
    }
    if (subscriptionType === "Select Months" || !subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (gymGoal === "Select Gym Goal") {
      setGymGoalPresent(true);
      fieldValidationSuccess = false;
    }
    if (address)
      if (address?.length < 10 || address?.length > 1000) {
        setAddressPresent(true);
        fieldValidationSuccess = false;
      }

    if (dateOfBirth >= getCurrentDate() || !dateOfBirth) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }
    if (lastPaidDate > getCurrentDate()) {
      setLastPaidDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (expiryDate <= getCurrentDate()) {
      setExpiryDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  useEffect(() => {
    if (packageType !== "Select Package") {
      if (Number(packageDetails[packageType]?.months === 1))
        setSubscriptionType(packageDetails[packageType]?.months + " Month");
      else setSubscriptionType(packageDetails[packageType]?.months + " Months");
      setSubscriptionAmount(packageDetails[packageType]?.offerPrice);
      setExtraDays(packageDetails[packageType]?.extraDays);
      const currentDate = new Date(joinedDate);
      let expDate = new Date(
        currentDate.setMonth(
          currentDate.getMonth() + packageDetails[packageType]?.months
        )
      );
      expDate.setDate(
        expDate.getDate() + packageDetails[packageType]?.extraDays - 1
      );
      setExpiryDate(new Date(expDate).toISOString().split("T")[0]);
    }
  }, [packageType]);

  const submitHandler = async (e) => {
    // e.preventDefault();
    // if (await verifyInputFeilds())
    try {
      const res = await createUser({
        payLoad: {
          gymId: userInfo.gymId._id,
          firstName,
          newUserId: userId,
          lastName,
          gender,
          mobileNumber,
          email,
          aadharNumber,
          heigthInCm,
          weightInKg,
          //ptEnabled,
          joinedDate,
          dateOfBirth,
          paidAmount: lastPaidAmount,
          lastPaidDate,
          renewedDate: joinedDate,
          subscriptionAmount: Number(subscriptionAmount) + Number(admissionFee),
          lastPaidAmount,
          transactionId: txnId,
          subscriptionType,
          selectedPackage:
            packageType === "Select Package" ? subscriptionType : packageType,
          gymGoal,
          address,
          notes: offerDetails,
          subscriptionExpiryDate: expiryDate,
        },
        token: userInfo.token,
      }).unwrap();
      toast.success(() => (
        <div>
          "Member Registered Successfully"
          <br />
          <br />
          Member Id : {res.userId}
        </div>
      ));
      await deleteUserSelf({
        userId: referenceId,
        token: userInfo.token,
      }).unwrap();

      //  dispatch(setCredentials({ ...res }));
      // navigate(redirect);
      setUserId("");
      setFirstName("");
      setLastName("");
      setGender("");
      setMobileNumber("");
      setEmail("");
      setAadharNumber("");
      setHeigthInCm("");
      setWeightInKg("");
      setTxnId("");
      //setPtEnabled(false);
      setDateOfBirth("");
      setLastPaidAmount("");
      setSubscriptionAmount("");
      setSubscriptionType("");
      setGymGoal("");
      setAddress("");
      setApproveEnabled(false);

      // navigate("/userRegistration");
    } catch (e) {
      toast.error(e?.data?.error);
    }
  };

  const handleCancel = () => {
    setApproveEnabled(false);
  };

  const handleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to Register this user ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
      // setEdit={() => paymentStatus(true)}
      />
      <RegistrationContainer>
        {/* <Form.Group as={Row} className="my-2" controlId="userId">
          <Form.Label column sm="3">
            Reference ID * :
          </Form.Label>
          <Col sm="6">
            <Form.Label>{userId}</Form.Label>
          </Col>
        </Form.Group> */}
        <Form.Group as={Row} className="my-2" controlId="userId">
          <Form.Label column sm="3">
            Member ID :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="enter Member Id"
            ></Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="firtsName">
          <Form.Label column sm="3">
            First Name <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="enter First Name"
            ></Form.Control>{" "}
            {firstNamePresent ? (
              <FieldWarning text="First name should have minimum 1 char and max 100 char" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="lastName">
          <Form.Label column sm="3">
            Last Name :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="enter Last name"
            ></Form.Control>{" "}
            {lastNamePresent ? (
              <FieldWarning text="Last name can not have more than 100 characters" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="mobileNumber">
          <Form.Label column sm="3">
            Mobile Number <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={mobileNumber}
              onChange={(e) => {
                const value = e.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setMobileNumber(value);
              }}
              placeholder="enter mobile number"
            />
            {mobileNumberPresent ? (
              <FieldWarning text="Please provide 10 digit Mobile number" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="joinedDate">
          <Form.Label column sm="3">
            Joined Date :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="date"
              value={joinedDate}
              onChange={(e) => setJoinedDate(e.target.value)}
              placeholder="in format 'YYYY-MM-DD'"
            ></Form.Control>
            {joinedDatePresent ? (
              <FieldWarning text="Joined date can not be future date" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        {packageList?.length > 1 && (
          <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column="true" sm="3">
              {preferredLanguage[selectedLanguage]?.registrationPage.package}{" "}
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={packageType}
                onChange={(e) => setPackageType(e.target.value)}
                placeholder="Select Package"
              >
                {packageList?.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>{" "}
              {subscriptionTypePresent ? (
                <FieldWarning text="Please select subscription type" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row} className="my-2" controlId="subscriptionType">
          <Form.Label column="true" sm="3">
            {
              preferredLanguage[selectedLanguage]?.registrationPage
                .subscriptionType
            }{" "}
            <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Select
              value={subscriptionType}
              onChange={(e) => setSubscriptionType(e.target.value)}
              placeholder="Select subscription Type"
            >
              {subscriptionList?.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>{" "}
            {subscriptionTypePresent ? (
              <FieldWarning text="Please select subscription type" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        {packageList?.length > 1 && (
          <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column="true" sm="3">
              {preferredLanguage[selectedLanguage]?.registrationPage.extraDays}{" "}
            </Form.Label>
            <Col sm="6">
              <Form.Control
                value={extraDays}
                // onChange={(e) => setPackageType(e.target.value)}
                disabled
              ></Form.Control>{" "}
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row} className="my-2" controlId="trxId">
          <Form.Label column="true" sm="3">
            {preferredLanguage[selectedLanguage]?.registrationPage.offerDetails}
            :
          </Form.Label>{" "}
          <Col sm="6">
            <Form.Control
              type="text"
              value={offerDetails}
              onChange={(e) => setOfferDetails(e.target.value)}
              placeholder="buy 3 months, 1 months free"
            ></Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="expiryDate">
          <Form.Label column="true" sm="3">
            {preferredLanguage[selectedLanguage]?.registrationPage.expiryDate}{" "}
            <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="date"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              placeholder="in format 'YYYY-MM-DD'"
            ></Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="subscriptionAmount">
          <Form.Label column="true" sm="3">
            {
              preferredLanguage[selectedLanguage]?.registrationPage
                .subscriptionAmount
            }{" "}
            <label style={{ color: "red" }}>*</label> :
          </Form.Label>{" "}
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={subscriptionAmount}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setSubscriptionAmount(value);
              }}
              placeholder="enter subscription Amount"
            />
            {subscriptionAmountPresent ? (
              <FieldWarning text="Subscription amount should be between 1 and 100000 Rs" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>{" "}
        {admissionFee !== null && admissionFee !== undefined && (
          <Form.Group as={Row} className="my-2" controlId="subscriptionAmount">
            <Form.Label column="true" sm="3">
              Admission Fee :
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={admissionFee}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAdmissionFee(value);
                }}
              />
            </Col>
          </Form.Group>
        )}
        <Form.Group as={Row} className="my-2" controlId="lastPaidAmount">
          <Form.Label column sm="3">
            Paid Amount <label style={{ color: "red" }}>*</label>::
          </Form.Label>{" "}
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={lastPaidAmount}
              onChange={(e) => {
                const value = e.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setLastPaidAmount(value);
              }}
              placeholder="Enter recently Paid Amount"
            />
            {lastPaidAmountPresent ? (
              <FieldWarning text="Please provide paid amount" />
            ) : (
              ""
            )}
            {paidAmountGreaterThanSubsAmount ? (
              <FieldWarning text="Paid amount can not be greater than Total amount" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="trxId">
          <Form.Label column sm="3">
            Payment Mode :
          </Form.Label>{" "}
          <Col sm="6">
            <Form.Control
              type="text"
              value={txnId}
              onChange={(e) => setTxnId(e.target.value)}
              placeholder="enter transaction Id"
            ></Form.Control>{" "}
            {txnIdPresent ? (
              <FieldWarning text="Please provide valid transaction id " />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="lastPaidDate">
          <Form.Label column sm="3">
            Paid Date :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="date"
              value={lastPaidDate}
              onChange={(e) => setLastPaidDate(e.target.value)}
              placeholder="in format 'YYYY-MM-DD'"
            ></Form.Control>{" "}
            {lastPaidDatePresent ? (
              <FieldWarning text="Paid date can not be future date" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
          <Form.Label column sm="3">
            Date Of Birth <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="date"
              value={dateOfBirth?.split("T")[0]}
              onChange={(e) => setDateOfBirth(e.target.value)}
              placeholder="in format 'YYYY-MM-DD'"
            ></Form.Control>
            {dateOfBirthPresent ? (
              <FieldWarning text="Please provide valid date of birth" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="gender">
          <Form.Label column sm="3">
            Select Gender <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <div className="horizontal-radio-group">
              {genderList.map((option) => (
                <Form.Check
                  key={option}
                  type="radio"
                  label={option}
                  name="genderRadio"
                  id={`genderRadio-${option}`}
                  value={option}
                  checked={gender === option}
                  onChange={(e) => setGender(e.target.value)}
                />
              ))}
            </div>{" "}
            {genderPresent ? <FieldWarning text="Please select gender" /> : ""}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="gymGoal">
          <Form.Label column sm="3">
            Gym Goal <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Select
              value={gymGoal}
              onChange={(e) => setGymGoal(e.target.value)}
              placeholder="Select Gym Goal"
            >
              {gymGoalList.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>{" "}
            {gymGoalPresent ? (
              <FieldWarning text="Please select gym goal" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        {/*<Form.Group as={Row} className="my-2" controlId="ptEnabled">
          <Form.Label column sm="3">
            Personal Trainer Availed ?{" "}
          </Form.Label>
          <Col sm="6">
            <div className="horizontal-radio-group">
              {[true, false].map((option) => (
                <div key={option}>
                  <Form.Check
                    type="radio"
                    label={option === true ? "YES" : "NO"}
                    name="ptEnabled"
                    id={`ptEnabled-${option}`}
                    value={option}
                    checked={ptEnabled === option}
                    onChange={(e) => setPtEnabled(e.target.value)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Form.Group>*/}
        <Form.Group as={Row} className="my-2" controlId="email">
          <Form.Label column sm="3">
            Email :
          </Form.Label>
          <Col sm="6">
            {" "}
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="enter email"
            ></Form.Control>
            {emailPresent ? (
              <FieldWarning text="Please provide valid e-mail" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="aadharNumber">
          <Form.Label column sm="3">
            Aadhar Number :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={aadharNumber}
              onChange={(e) => {
                const value = e.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setAadharNumber(value);
              }}
              placeholder="enter aadhar number"
            />
            {aadharNumberPresent ? (
              <FieldWarning text="Please provide 12 digit Aadhar number" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="heigthInCm">
          <Form.Label column sm="3">
            Heigth In Cm :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={heigthInCm}
              onChange={(e) => {
                const value = e.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setHeigthInCm(value);
              }}
              placeholder="enter heigth in centimeter"
            />
            {heigthInCmPresent ? (
              <FieldWarning text="Height should be between 80 cm and 230 cm" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="weightInKg">
          <Form.Label column sm="3">
            Weight In Kg :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={weightInKg}
              onChange={(e) => {
                const value = e.target.value?.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setWeightInKg(value);
              }}
              placeholder="enter weight in Kg"
            />
            {weightInKgPresent ? (
              <FieldWarning text="Weight should be between 20 kg and 200 kg" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="address">
          <Form.Label column sm="3">
            Address :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="enter Address"
            ></Form.Control>{" "}
            {addressPresent ? (
              <FieldWarning text="Address should be atleast 10 chars long" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
      </RegistrationContainer>
      <Row
        sm="4"
        style={{
          alignItems: "center",
          justifyContent: "center",
          margin: "2rem",
        }}
      >
        <Button
          className={`${userInfo.themeColor}ThemeHeaderTop`}
          type="submit"
          variant="primary"
          onClick={handleSubmit}
          style={{ maxWidth: "7rem" }}
        >
          {" "}
          Register
        </Button>{" "}
        <Button
          variant="secondary"
          onClick={handleCancel}
          style={{ marginLeft: "5%", maxWidth: "7rem" }}
        >
          {" "}
          Cancel
        </Button>
      </Row>
    </>
  );
};

export default UserSelfProfileScreen;

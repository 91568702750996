import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

function ReUsableModal(props) {
  const { userInfo } = useSelector((state) => state.auth);

  const [errors, setErrors] = useState({}); // State to track errors

  const validateField = (field, value) => {
    let error = "";

    // Validation for text
    if (field.fieldType === "text" && field.notAllowedValues?.includes(value)) {
      error = `This value is not allowed.`;
    }

    // Validation for date
    if (field.fieldType === "date") {
      const selectedDate = new Date(value);
      if (field.minimumDate && selectedDate < new Date(field.minimumDate)) {
        error = `Date cannot be earlier than ${field.minimumDate}.`;
      }
      if (field.maximumDate && selectedDate > new Date(field.maximumDate)) {
        error = `Date cannot be later than ${field.maximumDate}.`;
      }
    }

    // Validation for number
    if (field.fieldType === "number") {
      if (field.minimumValue !== undefined && value < field.minimumValue) {
        error = `Value cannot be less than ${field.minimumValue}.`;
      }
      if (field.maximumValue !== undefined && value > field.maximumValue) {
        error = `Value cannot be more than ${field.maximumValue}.`;
      }
    }

    // Validation for select
    if (
      field.fieldType === "select" &&
      field.notAllowedValues?.includes(value)
    ) {
      error = `This selection is not allowed.`;
    }

    return error;
  };

  const handleChange = (field, value) => {
    const error = validateField(field, value);
    setErrors((prevErrors) => ({ ...prevErrors, [field.fieldName]: error }));
    field.setMethod(value);
  };

  const handleSubmit = () => {
    const newErrors = {};
    props.payload.forEach((field) => {
      const error = validateField(field, field.fieldValue);
      if (error) newErrors[field.fieldName] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Prevent submission if there are errors
    }

    props.onSubmit();
    props.onHide();
  };

  const handleCancel = () => {
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <strong>{props.title}</strong>
          <p style={{ color: "red" }}>{props.restrictTitle}</p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {props.payload.map((field, index) => (
            <Form.Group
              as={Row}
              className="my-2"
              controlId={field.fieldName}
              key={index}
            >
              <Col md={4}>
                <Form.Label style={{ color: "black" }}>
                  {field.fieldName}:
                </Form.Label>
              </Col>
              <Col md={8}>
                {field.fieldType === "text" && (
                  <>
                    <Form.Control
                      type="text"
                      value={field.fieldValue}
                      placeholder={field.placeHolder}
                      onChange={(e) => handleChange(field, e.target.value)}
                      disabled={field.disabled}
                      style={field.style}
                    />
                    {errors[field.fieldName] && (
                      <small className="text-danger">
                        {errors[field.fieldName]}
                      </small>
                    )}
                  </>
                )}
                {field.fieldType === "date" && (
                  <>
                    <Form.Control
                      type="date"
                      value={field.fieldValue}
                      onChange={(e) => handleChange(field, e.target.value)}
                      disabled={field.disabled}
                      style={field.style}
                    />
                    {errors[field.fieldName] && (
                      <small className="text-danger">
                        {errors[field.fieldName]}
                      </small>
                    )}
                  </>
                )}
                {field.fieldType === "number" && (
                  <>
                    <Form.Control
                      type="number"
                      value={field.fieldValue}
                      placeholder={field.placeHolder}
                      onChange={(e) =>
                        handleChange(field, parseInt(e.target.value, 10))
                      }
                      disabled={field.disabled}
                      style={field.style}
                    />
                    {errors[field.fieldName] && (
                      <small className="text-danger">
                        {errors[field.fieldName]}
                      </small>
                    )}
                  </>
                )}
                {field.fieldType === "select" && (
                  <>
                    <Form.Select
                      value={field.fieldValue}
                      onChange={(e) => handleChange(field, e.target.value)}
                      disabled={field.disabled}
                      style={field.style}
                    >
                      {field.options.map((option, idx) => (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                    {errors[field.fieldName] && (
                      <small className="text-danger">
                        {errors[field.fieldName]}
                      </small>
                    )}
                  </>
                )}
              </Col>
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className={`${userInfo?.themeColor}ThemeHeaderTop`}
          onClick={handleSubmit}
          disabled={props.submitDisabled}
          style={props.submitStyle}
        >
          {props.submit || "Submit"}
        </Button>
        <Button
          variant="secondary"
          onClick={handleCancel}
          disabled={props.cancelDisabled}
          style={props.cancelStyle}
        >
          {props.cancel || "Cancel"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReUsableModal;

// SAMPLE RE-USABLE CODE
{
  /* <ReusableModal
  title="ReusableModal"
  payload={[
    {
      fieldName: "Name",
      fieldType: "text",
      placeHolder: "Enter name",
      fieldValue: demoName,
      setMethod: setDemoName,
      notAllowedValues: ["Invalid", "Test"],
      disabled: false,
      style: { backgroundColor: "#f8f9fa" },
    },
    {
      fieldName: "joinedDate",
      fieldType: "date",
      fieldValue: joinedDate,
      setMethod: setJoinedDate,
      minimumDate: "1970-01-01",
      maximumDate: "2025-02-13",
      disabled: false,
      style: { color: "blue" },
    },
  ]}
  show={modalShow}
  size="md"
  onHide={() => setModalShow(false)}
  onSubmit={submitEdit}
  submit="Submit"
  cancel="Cancel"
  submitDisabled={false}
  cancelDisabled={false}
  submitStyle={{ backgroundColor: "green", color: "white" }}
  cancelStyle={{ backgroundColor: "gray", color: "white" }}
/>; */
}

import { apiSlice } from "./apiSlice";
import { PTUSER_URL } from "../constants";

const ptUsersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPtUsers: builder.query({
      query: (body) => ({
        url: `${PTUSER_URL}${body.query ? body.query : ""}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),

    createPtUsers: builder.mutation({
      query: (data) => ({
        url: `${PTUSER_URL}`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    editPtDetails: builder.mutation({
      query: (data) => ({
        url: `${PTUSER_URL}`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetPtUsersQuery,
  useCreatePtUsersMutation,
  useEditPtDetailsMutation,
} = ptUsersApiSlice;

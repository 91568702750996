import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { useGetMyGymQuery } from "../slices/gymSlice";

import {
  useCreateUsersMutation,
  useUploadPhotoMutation,
} from "../slices/usersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";
import VerticallyCenteredModal from "../components/SubmitModal";
import Loader from "../components/Loader";
import preferredLanguage from "../assets/preferredLanguage.json";

const UserRegistrationScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [userId, setUserId] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("Unknown");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [aadharNumber, setAadharNumber] = useState();
  const [heigthInCm, setHeigthInCm] = useState();
  const [weightInKg, setWeightInKg] = useState();
  //const [ptEnabled, setPtEnabled] = useState(false);
  const [joinedDate, setJoinedDate] = useState(getCurrentDate());
  const [dateOfBirth, setDateOfBirth] = useState("2021-01-01");
  const [lastPaidDate, setLastPaidDate] = useState(getCurrentDate());
  const [subscriptionAmount, setSubscriptionAmount] = useState();
  const [lastPaidAmount, setLastPaidAmount] = useState();
  const [subscriptionType, setSubscriptionType] = useState();
  const [packageType, setPackageType] = useState("Select Package");
  const [gymGoal, setGymGoal] = useState("Muscle Gain and Strength Building");
  const [address, setAddress] = useState("");
  const [offerDetails, setOfferDetails] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [txnId, setTxnId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [file, setFile] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [extraDays, setExtraDays] = useState();
  const [admissionFee, setAdmissionFee] = useState(0);



  const [userIdStartWithZero, setUserIdStartWithZero] = useState(false);
  const [userIdTooLarge, setUserIdTooLarge] = useState(false);
  const [userIdPresent, setUserIdPresent] = useState(false);
  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState("");
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [heigthInCmPresent, setHeigthInCmPresent] = useState(false);
  const [weightInKgPresent, setWeightInKgPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);

  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  const [lastPaidDatePresent, setLastPaidDatePresent] = useState(false);
  const [subscriptionAmountPresent, setSubscriptionAmountPresent] =
    useState(false);
  const [lastPaidAmountPresent, setLastPaidAmountPresent] = useState(false);
  const [paidAmountGreaterThanSubsAmount, setPaidAmountGreaterThanSubsAmount] =
    useState(false);
  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState(false);
  const [gymGoalPresent, setGymGoalPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  const [txnIdPresent, setTxnIdPresent] = useState(false);
  const [fileInvalidError, setFileInvalidError] = useState(false);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  let {
    data: myGymDetails,
    isLoading: myGymDetailsLoading,
    error,
    refetch,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  // console.log(myGymDetails);
  const genderList = ["Male", "Female", "Other"];
  const subscriptionList = [
    "Select Months",
    "1 Month",
    "2 Months",
    "3 Months",
    "4 Months",
    "5 Months",
    "6 Months",
    "7 Months",
    "8 Months",
    "9 Months",
    "10 Months",
    "11 Months",
    "12 Months",
    "13 Months",
    "14 Months",
    "15 Months",
    "16 Months",
    "17 Months",
    "18 Months",
    "24 Months",
  ];
  const [packageList, setPackageList] = useState();
  const [packageDetails, setPackageDetails] = useState();

  useEffect(() => {
    refetch();
    if (!myGymDetailsLoading && myGymDetails) {
      const customPackage = myGymDetails?.customPackage || {};
      // console.log("customPackage:", customPackage);
      setPackageDetails(customPackage);
      setPackageList(["Select Package", ...Object.keys(customPackage)]);
      setAdmissionFee(myGymDetails?.admissionFee);
    }
  }, [myGymDetailsLoading, myGymDetails, refetch]);

  const gymGoalList = [
    "Select Gym Goal",
    "Weight Loss and Fat Reduction",
    "Muscle Gain and Strength Building",
    "Body Building",
    "Weight Lifting",
    "To Improve Stamina",
    "Stay Fit",
    "Health and Wellness",
    "Sports Performance",
    "Injury Rehabilitation",
  ];

  const navigate = useNavigate();

  const [createUser, { isLoading }] = useCreateUsersMutation();
  const [uploadPhoto, { isLoading: isPhotoUploading }] =
    useUploadPhotoMutation();


  // const userInfo = useSelector((state) => state.auth);

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate(redirect);
  //     }
  //   }, [userInfo, redirect, navigate]);

  useEffect(() => {
    if (userId?.toString().length > 0) {
      setUserIdPresent(false);
    }

    if (userId?.toString().length <= 7) {
      setUserIdTooLarge(false);
    }

    if (firstName?.trim().length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.trim().length > 0) {
      setLastNamePresent(false);
    }

    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (mobileNumber?.length === 10 && mobileNumber > 0) {
      setMobileNumberPresent(false);
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email.trim()) || email?.trim().length === 0) {
      setEmailPresent(false);
    }

    if (aadharNumber?.length === 12 || aadharNumber?.length === 0) {
      setAadharNumberPresent(false);
    }

    if (
      (Number(heigthInCm) >= 80 && Number(heigthInCm) < 230) ||
      heigthInCm?.length === 0
    ) {
      setHeigthInCmPresent(false);
    }

    if (
      (Number(weightInKg) >= 20 && Number(weightInKg) < 200) ||
      weightInKg?.length === 0
    ) {
      setWeightInKgPresent(false);
    }

    if (Number(lastPaidAmount) >= 0 && Number(lastPaidAmount) < 500001) {
      setLastPaidAmountPresent(false);
    }

    if (
      Number(lastPaidAmount) <
      Number(subscriptionAmount) + Number(admissionFee)
    ) {
      setPaidAmountGreaterThanSubsAmount(false);
    }

    if (Number(subscriptionAmount) > 0 && Number(subscriptionAmount) < 500001) {
      setSubscriptionAmountPresent(false);
    }

    if (txnId?.trim().length >= 0) {
      setTxnIdPresent(false);
    }

    if (
      subscriptionType !== undefined &&
      subscriptionType !== "Select Months"
    ) {
      setSubscriptionTypePresent(false);
    }

    if (gymGoal !== "Select Gym Goal") {
      setGymGoalPresent(false);
    }
    if (address?.trim().length > 10 || address?.length === 0) {
      setAddressPresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }
    if (lastPaidDate <= getCurrentDate()) {
      setLastPaidDatePresent(false);
    }
  }, [
    userId,
    firstName,
    lastName,
    gender,
    mobileNumber,
    email,
    aadharNumber,
    heigthInCm,
    weightInKg,
    // ptEnabled,
    dateOfBirth,
    lastPaidAmount,
    lastPaidDate,
    joinedDate,
    subscriptionAmount,
    txnId,
    subscriptionType,
    gymGoal,
    address,
  ]);

  useEffect(() => {

    if (joinedDate && subscriptionType && packageType === "Select Package") {
      const monthsLater = new Date(joinedDate);
      monthsLater.setMonth(
        monthsLater.getMonth() + parseInt(subscriptionType?.split(" ")[0])
      );
      monthsLater.setDate(monthsLater.getDate() - 1);
      setExpiryDate(monthsLater.toISOString().slice(0, 10));
    }
  }, [joinedDate, subscriptionType]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (userId)
      if (userId?.length < 1) {
        setUserIdPresent(true);
        fieldValidationSuccess = false;
      }

    if (userId?.length > 7) {
      setUserIdTooLarge(true);
      fieldValidationSuccess = false;
    }

    if (firstName?.trim().length < 1 || firstName?.trim().length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.trim().length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (
      Number(lastPaidAmount) >
      Number(subscriptionAmount) + Number(admissionFee)
    ) {
      setPaidAmountGreaterThanSubsAmount(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }

    if (mobileNumber?.length !== 10 || mobileNumber < 0) {
      setMobileNumberPresent(true);
      fieldValidationSuccess = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email.trim()) || email?.trim().length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }

    if (aadharNumber)
      if (aadharNumber?.length !== 12 || Number(aadharNumber) < 0) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }
    if (heigthInCm)
      if (Number(heigthInCm) < 80 || Number(heigthInCm) > 230) {
        setHeigthInCmPresent(true);
        fieldValidationSuccess = false;
      }

    if (weightInKg)
      if (Number(weightInKg) < 20 || Number(weightInKg) > 200) {
        setWeightInKgPresent(true);
        fieldValidationSuccess = false;
      }

    if (
      !lastPaidAmount ||
      Number(lastPaidAmount) < 0 ||
      Number(lastPaidAmount) > 500001
    ) {
      setLastPaidAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (
      !subscriptionAmount ||
      Number(subscriptionAmount) <= 0 ||
      Number(subscriptionAmount) > 500001
    ) {
      setSubscriptionAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (txnId?.trim().length > 40) {
      setTxnIdPresent(true);
      fieldValidationSuccess = false;
    }
    if (subscriptionType === "Select Months" || !subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (gymGoal === "Select Gym Goal") {
      setGymGoalPresent(true);
      fieldValidationSuccess = false;
    }
    if (
      (address?.trim().length < 10 && address?.trim().length > 0) ||
      address?.trim().length > 1000
    ) {
      setAddressPresent(true);
      fieldValidationSuccess = false;
    }

    if (dateOfBirth >= getCurrentDate() || !dateOfBirth) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }
    if (lastPaidDate > getCurrentDate()) {
      setLastPaidDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  useEffect(() => {
    // console.log("inside use effect");
    if (packageType !== "Select Package") {
      // console.log("inside ese effect 1");
      // console.log(packageDetails[packageType]?.months);
      // console.log(packageDetails[packageType]?.offerPrice);
      // console.log(packageDetails[packageType]?.extraDays);
      if (Number(packageDetails[packageType]?.months === 1))
        setSubscriptionType(packageDetails[packageType]?.months + " Month");
      else setSubscriptionType(packageDetails[packageType]?.months + " Months");
      setSubscriptionAmount(packageDetails[packageType]?.offerPrice);
      setExtraDays(packageDetails[packageType]?.extraDays);
      const currentDate = new Date(joinedDate);
      // console.log("currentDate");
      // console.log(currentDate);
      let expDate = new Date(
        currentDate.setMonth(
          currentDate.getMonth() + packageDetails[packageType]?.months
        )
      );
      // console.log("expDate");
      // console.log(expDate);
      expDate.setDate(
        expDate.getDate() + packageDetails[packageType]?.extraDays - 1
      );
      // console.log("expDate");
      // console.log(expDate);
      setExpiryDate(new Date(expDate).toISOString().split("T")[0]);
    }
  }, [packageType, joinedDate]);

  const submitHandler = async (e) => {
    // e.preventDefault();

    try {
      const res = await createUser({
        payLoad: {
          gymId: userInfo.gymId._id,
          firstName: firstName.trim(),
          newUserId: userId?.toString().toLowerCase(),
          lastName: lastName?.trim(),
          gender,
          mobileNumber,
          email: email?.trim(),
          aadharNumber,
          heigthInCm,
          selectedPackage:
            packageType === "Select Package" ? subscriptionType : packageType,
          heigthInCm,
          weightInKg,
          // ptEnabled,
          joinedDate,
          dateOfBirth,
          paidAmount: lastPaidAmount,
          lastPaidDate,
          renewedDate: joinedDate,
          subscriptionAmount: Number(subscriptionAmount) + Number(admissionFee),
          lastPaidAmount,
          transactionId: txnId?.trim(),
          subscriptionType,
          gymGoal,
          address: address?.trim(),
          notes: offerDetails,
          subscriptionExpiryDate: expiryDate,
        },
        token: userInfo.token,
      }).unwrap();
      let imageUploadRes;
      if (res && file) {
        let formData = new FormData();
        formData.append("image", file);
        formData.append("imageKey", `${userInfo.gymId?._id}${res.userId}`);
        imageUploadRes = await uploadPhoto({
          token: userInfo.token,
          payload: formData,
        }).unwrap();
      }
      if (res && imageUploadRes)
        toast.success(() => (
          <div>
            "Member Registered and Photo uploaded Successfully"
            <br />
            <br />
            Member Id : {res.userId}
          </div>
        ));

      if (res && !imageUploadRes)
        toast.success(() => (
          <div>
            "Member Registered Successfully, but Photo is not Uploaded"
            <br />
            <br />
            Member Id : {res.userId}
          </div>
        ));

      //  dispatch(setCredentials({ ...res }));
      // navigate(redirect);
      // if (res && file)
      //   await uploadPhoto({
      //     token: userInfo.token,
      //     payload: file,
      //   }).unwrap();
      setUserId("");
      setFirstName("");
      setLastName("");
      setMobileNumber("");
      setEmail("");
      setAadharNumber("");
      setHeigthInCm("");
      setWeightInKg("");
      setTxnId("");
      setGender("Unknown");
      // setPtEnabled(false);
      setDateOfBirth("2021-01-01");
      setJoinedDate(getCurrentDate());
      setLastPaidDate(getCurrentDate());
      setLastPaidAmount("");
      setSubscriptionAmount("");
      setSubscriptionType("");
      setAddress("");
      setFile(null);
      setFileInvalidError(false);
      setFileSizeError(false);
      setExpiryDate("");
      setOfferDetails("");
      setPackageType("Select Package");
      setExtraDays(0);
      navigate("/userRegistration");
    } catch (e) {
      e?.data?.message &&
        toast.error(
          "Please provide " +
          e?.data?.message
            .replace("Path", "")
            .replace(".0", "")
            .split(":")[1]
            .split(",")[0]
        );
      e?.data?.error && toast.error(e?.data?.error);
    }
  };

  const compressImage = async (selectedImage) => {
    const file = selectedImage;
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 280,
          useWebWorker: true,
          initialQuality: 1,
        };

        console.log("Un-Compressed file:", file);
        const compressedFile = await imageCompression(file, options);
        // setFile(compressedFile);
        console.log("first Compressed file:", compressedFile);
        const secondOptions = {
          maxSizeMB: 1, // maximum size in MB
          useWebWorker: true, // use web worker for performance
          initialQuality: 0.9, // initial quality setting
        };

        // Second compression step
        const secondCompressedFile = await imageCompression(
          compressedFile,
          secondOptions
        );
        console.log("Second Compressed file:", secondCompressedFile);
        setFile(secondCompressedFile);

        // // You can now upload the compressed file to your server
        // uploadImage(compressedFile);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e?.target?.files[0];
    const dotIndex = selectedFile?.name?.lastIndexOf(".");
    const fileExtension = selectedFile?.name?.slice(dotIndex + 1).toLowerCase();
    let errorPresent = false;
    if (
      !(
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "png" ||
        fileExtension === "tiff" ||
        fileExtension === "svg" ||
        fileExtension === "jfif"
      )
    ) {
      errorPresent = true;
      setFileInvalidError(true);
      setFile(null);
    } else if (selectedFile?.size / 1000 > 5120) {
      setFileSizeError(true);
      setFile(null);
    } else if (!errorPresent && selectedFile?.size / 1000 <= 5120) {
      setFileSizeError(false);
      setFileInvalidError(false);
      // setFile(selectedFile);
      await compressImage(selectedFile);
    }
  };

  const handleSubmit = async () => {
    try {
      if (await verifyInputFeilds()) setModalShow(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <label>
        <strong>
          {
            preferredLanguage[selectedLanguage]?.registrationPage
              .memberRegistration
          }
        </strong>
      </label>{" "}
      <VerticallyCenteredModal
        title="Are you sure want to Register this user ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
      // setEdit={() => paymentStatus(true)}
      />
      {isLoading || (isPhotoUploading && <Loader />)}
      <Row>
        <Col md={5}>
          <Form.Group as={Row} className="my-2" controlId="userId">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.memberID}
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={userId}
                onChange={(e) => {
                  // Update userId only if input is alphanumeric (no special characters)
                  const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ""); // Allow only letters and numbers
                  setUserId(newValue);
                }}
                placeholder="enter Member ID"
              />
              {userIdPresent ? (
                <FieldWarning text="Member ID must be atleast 1 digit long." />
              ) : (
                ""
              )}

              {userIdTooLarge ? (
                <FieldWarning text="Member ID must be less than 7 digits" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.firstName}
              <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="enter First Name"
              ></Form.Control>{" "}
              {firstNamePresent ? (
                <FieldWarning text="First name should have minimum 1 char and max 100 char" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastName">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.lastName}
            </Form.Label>
            <Col sm="6">
              <Form.Control
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="enter Last name"
              ></Form.Control>{" "}
              {lastNamePresent ? (
                <FieldWarning text="Last name can not have more than 100 characters" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="mobileNumber">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .mobileNumber
              }{" "}
              <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={mobileNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setMobileNumber(value);
                }}
                placeholder="enter mobile number"
              />
              {mobileNumberPresent ? (
                <FieldWarning text="Please provide 10 digit Mobile number" />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="joinedDate">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.joinedDate}{" "}
              <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={joinedDate}
                onChange={(e) => setJoinedDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {joinedDatePresent ? (
                <FieldWarning text="Joined date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          {packageList?.length > 1 && (
            <Form.Group as={Row} className="my-2" controlId="subscriptionType">
              <Form.Label column="true" sm="4">
                {preferredLanguage[selectedLanguage]?.registrationPage.package}{" "}
              </Form.Label>
              <Col sm="6">
                <Form.Select
                  value={packageType}
                  onChange={(e) => setPackageType(e.target.value)}
                  placeholder="Select Package"
                >
                  {packageList?.map((e) => (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  ))}
                </Form.Select>{" "}
                {subscriptionTypePresent ? (
                  <FieldWarning text="Please select subscription type" />
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .subscriptionType
              }{" "}
              <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                disabled={packageType !== "Select Package"}
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                placeholder="Select subscription Type"
              >
                {subscriptionList?.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>{" "}
              {subscriptionTypePresent ? (
                <FieldWarning text="Please select subscription type" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          {packageList?.length > 1 && (
            <Form.Group as={Row} className="my-2" controlId="subscriptionType">
              <Form.Label column="true" sm="4">
                {
                  preferredLanguage[selectedLanguage]?.registrationPage
                    .extraDays
                }{" "}
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  value={extraDays}
                  // onChange={(e) => setPackageType(e.target.value)}
                  disabled
                ></Form.Control>{" "}
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className="my-2" controlId="trxId">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .offerDetails
              }
              :
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                value={offerDetails}
                onChange={(e) => setOfferDetails(e.target.value)}
                placeholder="buy 3 months, 1 months free"
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="expiryDate">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.expiryDate}{" "}
              <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="subscriptionAmount">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .subscriptionAmount
              }{" "}
              <label style={{ color: "red" }}>*</label> :
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={subscriptionAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setSubscriptionAmount(value);
                }}
                placeholder="enter subscription Amount"
              />
              {subscriptionAmountPresent ? (
                <FieldWarning text="Subscription amount should be between 1 and 500000 Rs" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          {admissionFee !== null && admissionFee !== undefined && (
            <Form.Group
              as={Row}
              className="my-2"
              controlId="subscriptionAmount"
            >
              <Form.Label column="true" sm="4">
                Admission Fee :
              </Form.Label>{" "}
              <Col sm="6">
                <Form.Control
                  type="text"
                  pattern="[0-9]*" // Allow only digits
                  value={admissionFee}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                    setAdmissionFee(value);
                  }}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className="my-2" controlId="lastPaidAmount">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.paidAmount}{" "}
              <label style={{ color: "red" }}>*</label>:
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={lastPaidAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setLastPaidAmount(value);
                }}
                placeholder="Enter Paid Amount"
              />
              {lastPaidAmountPresent ? (
                <FieldWarning text="Please provide paid amount" />
              ) : (
                ""
              )}
              {paidAmountGreaterThanSubsAmount ? (
                <FieldWarning text="Paid amount can not be greater than Total amount" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .dateOfBirth
              }{" "}
              <label style={{ color: "red" }}>*</label> :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {dateOfBirthPresent ? (
                <FieldWarning text="Please provide valid date of birth" />
              ) : (
                ""
              )}
            </Col>{" "}
          </Form.Group>{" "}
        </Col>
        <Col md={5}>
          <Form.Group as={Row} className="my-2" controlId="trxId">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .transactionId
              }
              :
            </Form.Label>{" "}
            <Col sm="6">
              <Form.Control
                type="text"
                value={txnId}
                onChange={(e) => setTxnId(e.target.value)}
                placeholder="enter transaction Id"
              ></Form.Control>
              {txnIdPresent ? (
                <FieldWarning text="Please provide valid transaction id " />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastPaidDate">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.paidDate} :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="date"
                value={lastPaidDate}
                onChange={(e) => setLastPaidDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {lastPaidDatePresent ? (
                <FieldWarning text="Paid date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          { /* <Form.Group as={Row} className="my-2" controlId="ptEnabled">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .personalTrainerAvailed
              }{" "}
            </Form.Label>
            <Col sm="6">
              <div className="horizontal-radio-group">
                {[true, false].map((option) => (
                  <div key={option}>
                    <Form.Check
                      key={option}
                      type="radio"
                      label={option === true ? "YES" : "NO"}
                      name="ptEnabled"
                      id={`ptEnabled-${option}`}
                      value={option}
                      checked={ptEnabled === option}
                      onChange={(e) => setPtEnabled(option)}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Form.Group>*/}
          <Form.Group as={Row} className="my-2" controlId="gymGoal">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.gymGoal} :
            </Form.Label>
            <Col sm="6">
              <Form.Select
                value={gymGoal}
                onChange={(e) => setGymGoal(e.target.value)}
                placeholder="Select Gym Goal"
              >
                {gymGoalList?.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>{" "}
              {gymGoalPresent ? (
                <FieldWarning text="Please select gym goal" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gender">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .selectGender
              }{" "}
              :
            </Form.Label>
            <Col sm="6">
              <div className="horizontal-radio-group">
                {genderList?.map((option) => (
                  <Form.Check
                    key={option}
                    type="radio"
                    label={option}
                    name="genderRadio"
                    id={`genderRadio-${option}`}
                    value={option}
                    checked={gender === option}
                    onChange={(e) => setGender(e.target.value)}
                  />
                ))}
              </div>{" "}
              {genderPresent ? (
                <FieldWarning text="Please select gender" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="email">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.email} :
            </Form.Label>
            <Col sm="6">
              {" "}
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="enter email"
              ></Form.Control>
              {emailPresent ? (
                <FieldWarning text="Please provide valid e-mail" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="aadharNumber">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .aadharNumber
              }{" "}
              :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={aadharNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAadharNumber(value);
                }}
                placeholder="enter aadhar number"
              />
              {aadharNumberPresent ? (
                <FieldWarning text="Please provide 12 digit Aadhar number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="heigthInCm">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.heigthInCm}
              :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={heigthInCm}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setHeigthInCm(value);
                }}
                placeholder="enter heigth in centimeter"
              />
              {heigthInCmPresent ? (
                <FieldWarning text="Height should be between 80 cm and 230 cm" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="weightInKg">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.weightInKg}
              :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={weightInKg}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setWeightInKg(value);
                }}
                placeholder="enter weight in Kg"
              />
              {weightInKgPresent ? (
                <FieldWarning text="Weight should be between 20 kg and 200 kg" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="address">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.address} :
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="enter Address"
              ></Form.Control>
              {addressPresent ? (
                <FieldWarning text="Address should be atleast 10 chars long" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="photo">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .uploadPhoto
              }
              :
            </Form.Label>
            <Col sm="6">
              <input
                type="file"
                onChange={handleFileChange}
                style={{ color: "white" }}
              />
              {fileSizeError && (
                <label style={{ color: "red" }}>
                  Image size should be less than 5 MB, Please select different
                  Image.
                </label>
              )}
              {fileInvalidError && (
                <label style={{ color: "red" }}>
                  Only jpeg, jpg, png, tiff and svg formats are supported
                </label>
              )}
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row
        sm="4"
        style={{
          alignItems: "center",
          justifyContent: "center",
          margin: "2rem 0rem",
        }}
      >
        <Button
          className={`${userInfo.themeColor}ThemeHeaderTop`}
          type="submit"
          disabled={isLoading}
          variant="primary"
          onClick={handleSubmit}
          style={{ maxWidth: "10rem" }}
        >
          {" "}
          {preferredLanguage[selectedLanguage]?.registrationPage.register}
        </Button>
      </Row>
    </>
  );
};

export default UserRegistrationScreen;

import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCurrentDate } from "../utils/getCurrentDate";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import { useEditPtDetailsMutation } from "../slices/ptUsersSlice";

const PtPaymentScreen = ({ user, paymentStatus }) => {
  const [userId, setUserId] = useState(user.userId);
  const [firstName, setFirstName] = useState(user.firstName);
  const [pendingAmount, setPendingAmount] = useState(user.ptPendingAmount);
  const [txnId, setTxnId] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [ptStartDate, setForPtStartDate] = useState(
    user.ptStartDate
      ? user.ptStartDate?.split("T")[0]
      : new Date().toISOString().split("T")[0]
  );
  const [lastPaidDate, setLastPaidDate] = useState(getCurrentDate());
  const [lastPaidAmount, setLastPaidAmount] = useState();

  const [renewedDatePresent, setRenewedDatePresent] = useState(false);
  const [lastPaidDatePresent, setLastPaidDatePresent] = useState(false);
  const [lastPaidAmountPresent, setLastPaidAmountPresent] = useState(false);
  const [
    paidAmountGreaterThanPendingAmount,
    setPaidAmountGreaterThanPendingAmount,
  ] = useState(false);
  const [txnIdPresent, setTxnIdPresent] = useState(false);

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [editPt, { isLoading }] = useEditPtDetailsMutation();

  useEffect(() => {
    if (Number(lastPaidAmount) >= 0 && Number(lastPaidAmount) < 50000) {
      setLastPaidAmountPresent(false);
    }

    if (Number(lastPaidAmount) < Number(pendingAmount)) {
      setPaidAmountGreaterThanPendingAmount(false);
    }

    if (txnId?.length > 0) {
      setTxnIdPresent(false);
    }
  }, [lastPaidAmount, lastPaidDate, ptStartDate, txnId]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (Number(lastPaidAmount) > Number(pendingAmount)) {
      setPaidAmountGreaterThanPendingAmount(true);
      fieldValidationSuccess = false;
    }

    if (
      !lastPaidAmount ||
      Number(lastPaidAmount) < 0 ||
      Number(lastPaidAmount) > 500001
    ) {
      setLastPaidAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (txnId?.length > 40) {
      setTxnIdPresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async () => {
    // e.preventDefault();

    try {
      await editPt({
        payload: {
          userId,
          paidDate: lastPaidDate,
          paidAmount: lastPaidAmount,
          ptStartDate: ptStartDate,
          transactionId: txnId,
        },
        token: userInfo.token,
      }).unwrap();
      navigate(`/users/${user.userId}`);
      paymentStatus();
      toast.success("PT Payment is Updated");
    } catch (e) {
      toast.error(e?.data?.message || e.data);
    }
  };
  const handleCancelButton = () => {
    navigate(`/users/${user.userId}`);
    paymentStatus();
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      <h5> Add PT Payment </h5>
      <VerticallyCenteredModal
        title="Are you sure want to update this PT payment ?"
        size="lg"
        payload={{
          "Paid Date": lastPaidDate,
          "Paid Amount": lastPaidAmount,
          "PT Start Date": ptStartDate,
          "Transaction Id": txnId,
        }}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
        // setEdit={() => paymentStatus(true)}
      />
      <Row>
        <Col md={8}>
          <Form.Group as={Row} className="my-2" controlId="userId">
            <Form.Label column="true" sm="4">
              ID :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{userId}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="4">
              Name :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{firstName}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="pendingAmount">
            <Form.Label column="true" sm="4">
              pendingAmount :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{pendingAmount}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastPaidAmount">
            <Form.Label column="true" sm="4">
              Amount Paid * :
            </Form.Label>{" "}
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={lastPaidAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setLastPaidAmount(value);
                }}
                placeholder="Enter recently Paid Amount"
              />
              {lastPaidAmountPresent ? (
                <FieldWarning text="Please provide paid amount" />
              ) : (
                ""
              )}
              {paidAmountGreaterThanPendingAmount ? (
                <FieldWarning text="Paid amount can not be greater than pending amount" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>{" "}
          <Form.Group as={Row} className="my-2" controlId="txnId">
            <Form.Label column="true" sm="4">
              Transaction Id :
            </Form.Label>{" "}
            <Col sm="5">
              <Form.Control
                type="text"
                value={txnId}
                onChange={(e) => setTxnId(e.target.value)}
                placeholder="enter transaction ID"
              ></Form.Control>
              {txnIdPresent ? (
                <FieldWarning text="Please provide valid transaction id " />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>{" "}
          <Form.Group as={Row} className="my-2" controlId="lastPaidDate">
            <Form.Label column="true" sm="4">
              Paid Date :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={lastPaidDate}
                onChange={(e) => setLastPaidDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>{" "}
              {lastPaidDatePresent ? (
                <FieldWarning text="Paid date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className="my-2" controlId="forRenewalDate">
            <Form.Label column="true" sm="4">
              for Renewal Date :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={forRenewalDate}
                onChange={(e) => setForRenewalDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>{" "}
              {renewedDatePresent ? (
                <FieldWarning text="Renewed date can not be future date " />
              ) : (
                ""
              )}
            </Col>
          </Form.Group> */}
          <Row
            sm="4"
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "2rem 0rem",
            }}
          >
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              type="submit"
              disabled={isLoading}
              variant="primary"
              onClick={hanbdleSubmit}
              style={{ maxWidth: "7rem" }}
            >
              {" "}
              Submit
            </Button>
            <Button
              type="button"
              disabled={isLoading}
              variant="secondary"
              onClick={handleCancelButton}
              style={{ marginLeft: "5%", maxWidth: "7rem" }}
            >
              {" "}
              Cancel
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PtPaymentScreen;

import axios from "axios";
import { getCurrentDate } from "./getCurrentDate";

export const getTodaysVisitors = async (userInfo) => {
  try {
    const requestParams = {
      reminderDate: getCurrentDate(),
    };

    const response = await axios.get(
      `http://localhost:4001/api/visitors?reminderDate=${getCurrentDate()}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
        params: requestParams,
      }
    );
    const visitors = response?.data;
    return visitors; // If you need to return it for further use
  } catch (error) {
    console.error("Error fetching visitors:", error.response?.data || error);
    return [];
  }
};

export const getTodaysExpiredUsers = async (userInfo) => {
  try {
    const requestParams = {
      gymId: userInfo.gymId._id,
      subscriptionExpiryDate: getCurrentDate(),
    };

    const response = await axios.get(`http://localhost:4001/api/users`, {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
      params: requestParams,
    });
    const users = response?.data;
    return users; // If you need to return it for further use
  } catch (error) {
    console.error("Error fetching users:", error.response?.data || error);
    return [];
  }
};

import React, { useEffect, useId, useState } from "react";
import {
  Row,
  Col,
  ListGroup,
  Card,
  Table,
  Form,
  Button,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import Message from "../components/Message";

import UserEditScreen from "./UserEditScreen";
import {
  useDeleteUserDataMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../slices/usersSlice";
import Loader from "../components/Loader";
import RenewalScreen from "./RenewalScreen";
import PaymentScreen from "./PaymentScreen";
import UpdateExpiryScreen from "./UpdateExpiryScreen";
import ProfileImage from "../components/ProfileImage";
import { useUploadPhotoMutation } from "../slices/usersSlice";
import generateInvoicePDF from "../utils/generateInvoice";
import { useGetPaymentsQuery } from "../slices/paymentSlice";
import { useGetRenewalsQuery } from "../slices/renewalSlice";
import preferredLanguage from "../assets/preferredLanguage.json";
import {
  useCreatePtUsersMutation,
  useGetPtUsersQuery,
} from "../slices/ptUsersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import PtPaymentScreen from "./PtPaymentScreen";
import ReUsableModal from "../components/ReUsableModal";

const UserProfileScreen = () => {
  const { id: userId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const [selectedLanguage, setSelectedLanguage] = useState(
    userInfo.preferredLanguage ? userInfo.preferredLanguage : "English"
  );
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileInvalidError, setFileInvalidError] = useState(false);
  const [deletePressed, setDeletePressed] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const [deleteUserName, setDeleteUserName] = useState();
  const [adminPwd, setAdminPwd] = useState();
  const [pwdVisible, setPwdVisible] = useState(false);
  const [ptButtonClicked, setPTButtonClicked] = useState(false);
  //const [ptMemberId, setPtMemberId] = useState();
  const [trainerName, setTrainerName] = useState();
  const [ptDays, setPtDays] = useState();
  const [ptAmount, setPtAmount] = useState();
  const [ptPaidAmount, setPtPaidAmount] = useState();
  const [ptFromDate, setPtFromDate] = useState(getCurrentDate());
  const [ptToDate, setPtToDate] = useState();
  const [ptPendingAmount, setPtPendingAmount] = useState();
  const [ptExistingPendingAmount, setPtExistingPendingAmount] = useState();
  const [ptExistingFromDate, setPtExistingFromDate] = useState();
  const [ptPaidDate, setPtPaidDate] = useState();
  const [ptTransactionId, setPtTransactionId] = useState();

  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate(userInfo.lastVisitedPage);
  };

  const {
    data: user,
    isLoading,
    refetch,
  } = useGetUserQuery({ payLoad: userId, token: userInfo.token });

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&userId=${userId}`
  );

  const [renewalQuery, setRenewalQuery] = useState(
    `?gymId=${userInfo.gymId._id}&userId=${userId}`
  );

  const compositeId = `${userInfo.gymId._id}${userId}`;

  const [getPtApiQuery, setGetPtApiQuery] = useState(
    `?compositeId=${compositeId}&sort=-createdAt`
  );

  const setPwdVisibility = () => {
    setPwdVisible(!pwdVisible);
  };

  const {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentsError,
    refetch: paymentsRefetch,
  } = useGetPaymentsQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: renewals,
    isLoading: renewalsLoading,

    refetch: renewalsRefetch,
  } = useGetRenewalsQuery({
    query: renewalQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: ptDetails,
    isLoading: ptLoading,
    error: ptError,
    refetch: ptRefetch,
  } = useGetPtUsersQuery({
    query: getPtApiQuery,
    token: userInfo?.token,
  });

  const [uploadPhoto, { isLoading: isPhotoUploading }] =
    useUploadPhotoMutation();

  const [deleteUserData, { isLoading: isDeleteLoading }] =
    useDeleteUserDataMutation();

  const [createPtUser, { isLoading: isPtUserLoading }] =
    useCreatePtUsersMutation();

  const [editUserDetails, { isLoading: isEditUserDetailLoading }] =
    useUpdateUserMutation();

  const [ptEnabled, setPtEnabled] = useState(false);

  const [editable, setEditable] = useState(false);
  const [renewal, setRenewal] = useState(false);
  const [payment, setPayment] = useState(false);
  const [ptPayment, setPtPayment] = useState(false);
  const [editExpiryDate, setEditExpiryDate] = useState(false);

  const [paymentsToList, setPaymentsToList] = useState(
    paymentsLoading ? [] : payments ? payments : []
  );

  const [userDetails, setUserDetails] = useState(
    isLoading ? {} : user ? user : {}
  );

  /* const [latestPtDetails, setLatestPtDetails] = useState(
     ptLoading ? [] : ptDetails ? ptDetails : []
   );*/

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handleRenewalStatus = () => {
    setRenewal(false);
  };

  const handlePaymentStatus = () => {
    setPayment(false);
  };

  const handlePtPaymentStatus = () => {
    setPtPayment(false);
  };

  const handleEditExpiryDateStatus = () => {
    setEditExpiryDate(false);
  };

  useEffect(() => {
    renewalsRefetch();
    paymentsRefetch();
    const allPays = paymentsLoading ? [] : payments;
    setPaymentsToList(allPays);
  }, [payments, renewals, user]);

  useEffect(() => {
    refetch();
    setUserDetails(isLoading ? {} : user ? user : {});
  }, [user]);

  useEffect(() => {
    refetch();
    ptRefetch();
  }, [refetch, editable, renewal, payment, editExpiryDate]);

  useEffect(() => {
    ptRefetch();
  }, [ptRefetch, ptPayment]);

  const [allPtDetails, setAllPtDetails] = useState();

  useEffect(() => {
    if (ptEnabled) {
      refetch();
      ptRefetch(); // Fetch updated user data when ptEnabled changes
    }
  }, [ptEnabled, refetch]);

  // useEffect(() => {

  //   const abcd = ptLoading ? null : ptDetails ? ptDetails : null;
  //   if (abcd) {
  //     console.log("abcd");
  //     console.log(abcd);
  //     console.log(abcd[0].trainerName);
  //     setPtFromDate(ptDetails[0].ptStartDate);
  //     setPtToDate(ptDetails[0].ptEndDate
  //     );
  //   }

  // }, [ptDetails]);

  useEffect(() => {
    if (!ptLoading && ptDetails) {
      console.log(ptDetails);
      setAllPtDetails(ptDetails);
      // const today = new Date().toISOString().split("T")[0];
      // setPtDays(ptDetails[0].ptDays);
      // setTrainerName(ptDetails[0].trainerName);
      // setPtFromDate(ptDetails[0].ptStartDate?.split("T")[0]);
      // setPtToDate(ptDetails[0].ptEndDate.split("T")[0]);
      // setPtAmount(ptDetails[0].ptAmount);
      // setPtPaidAmount(ptDetails[0].paidAmount);
      // setPtPendingAmount(ptDetails[0].pendingAmount);

      // setPtPaidDate(today);
      // setPtTransactionId(ptDetails[0].transactionId);

      setPtExistingPendingAmount(ptDetails[0].pendingAmount);
      setPtExistingFromDate(ptDetails[0].ptStartDate?.split("T")[0]);
    }
  }, [ptDetails, ptLoading]);

  const compressImage = async (selectedImage) => {
    const file = selectedImage;
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 280,
          useWebWorker: true,
          initialQuality: 1,
        };

        console.log("Un-Compressed file:", file);
        const compressedFile = await imageCompression(file, options);
        // setFile(compressedFile);
        console.log("first Compressed file:", compressedFile);
        const secondOptions = {
          maxSizeMB: 1, // maximum size in MB
          useWebWorker: true, // use web worker for performance
          initialQuality: 0.9, // initial quality setting
        };

        // Second compression step
        const secondCompressedFile = await imageCompression(
          compressedFile,
          secondOptions
        );
        console.log("Second Compressed file:", secondCompressedFile);
        setFile(secondCompressedFile);

        // // You can now upload the compressed file to your server
        // uploadImage(compressedFile);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e?.target?.files[0];
    const dotIndex = selectedFile?.name?.lastIndexOf(".");
    const fileExtension = selectedFile?.name?.slice(dotIndex + 1).toLowerCase();
    let errorPresent = false;
    if (
      !(
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "png" ||
        fileExtension === "tiff" ||
        fileExtension === "svg" ||
        fileExtension === "jfif"
      )
    ) {
      errorPresent = true;
      setFileInvalidError(true);
      setFile(null);
    } else if (selectedFile?.size / 1000 > 5120) {
      setFileSizeError(true);
      setFile(null);
    } else if (!errorPresent && selectedFile?.size / 1000 <= 5120) {
      setFileSizeError(false);
      setFileInvalidError(false);
      await compressImage(selectedFile);
      // setFile(selectedFile);
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        let formData = new FormData();
        formData.append("image", file);
        formData.append(
          "imageKey",
          `${userInfo?.gymId?._id}${user?._id?.toString().substring(4)}`
        );
        const imageUploadRes = await uploadPhoto({
          token: userInfo.token,
          payload: formData,
        }).unwrap();

        if (imageUploadRes) {
          toast.success(() => (
            <div>
              "Photo uploaded Successfully, Please refresh the page"
              <br />
            </div>
          ));
        }
        setFile(null);
      }
    } catch (e) {
      console.log(e);
      toast.error("Photo upload failed");
    }
  };

  const generateBill = async () => {
    // Example customer and invoice details
    const customerDetails = {
      userId: user?._id,
      userName: user?.firstName + " " + user?.lastName,
      mobileNumber: user?.mobileNumber,
      gymName: userInfo.gymId.name,
    };

    const invoiceDetails = {
      invoiceDate: user?.lastPaidDate,
      description: "Membership Fee",
      sNo: 1,
      subsAmount: user?.subscriptionAmount,
      paidAmount: user?.paidAmount,
      pendingAmount: user?.pendingAmount,
      startDate: user?.renewedDate,
      endDate: user?.subscriptionExpiryDate,
    };

    await generateInvoicePDF(customerDetails, invoiceDetails);
  };

  const handleDelete = async () => {
    try {
      const resData = await deleteUserData({
        userId: deleteUserId,
        adminPwd,
        token: userInfo?.token,
      }).unwrap();
      // console.log("resData");
      // console.log(resData);
      if (resData) {
        toast.success(resData.message);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      }
    } catch (e) {
      console.log(e);
      toast.error("Delete failed : " + e.data?.error);
    }
  };

  const confirmDelete = async () => {
    if (
      userId === deleteUserId &&
      deleteUserName ===
        (userDetails?.lastName?.length > 0
          ? `${userDetails?.firstName} ${userDetails?.lastName}`
          : userDetails?.firstName)
    ) {
      handleDelete();
      setDeletePressed(false);
      setDeleteUserId("");
      setDeleteUserName("");
      setAdminPwd("");
    } else {
      toast.error("Please enter correct member details");
    }
  };

  const handleCancelDelete = async () => {
    setDeletePressed(false);
    setDeleteUserId("");
    setAdminPwd("");
    setDeleteUserName("");
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setPtPaidDate(today);
  }, []);

  useEffect(() => {
    const pending = ptAmount - ptPaidAmount;
    setPtPendingAmount(pending >= 0 ? pending : 0);
  }, [ptAmount, ptPaidAmount]);

  const ptHandleSubmit = async (e) => {
    // e.preventDefault();
    try {
      const ptFrom = new Date(ptFromDate);
      const ptEndsOn = new Date(
        ptFrom.setDate(ptFrom.getDate() + Number(ptDays) - 1)
      )
        ?.toISOString()
        ?.split("T")[0];
      // console.log("ptEndsOn");
      // console.log(ptDays);
      // console.log(ptEndsOn);
      const compositeId = userInfo.gymId._id + userId;
      const res = await createPtUser({
        payload: {
          compositeId,
          ptDays,
          trainerName: trainerName,
          ptAmount: ptAmount,
          paidAmount: ptPaidAmount,
          transactionId: ptTransactionId,
          ptStartDate: ptFromDate,
          ptEndDate: ptEndsOn,
          paidDate: ptPaidDate,
        },
        token: userInfo.token,
      }).unwrap();

      if (res) {
        toast.success("Details updated successfully");
        await editUserDetails({
          userId,
          payLoad: {
            ptEnabled: true,
          },
          token: userInfo.token,
        }).unwrap();

        setPtEnabled(true);

        // Reset state only if request is successful
        setPTButtonClicked(false);
        setTrainerName();
        setPtFromDate(getCurrentDate());
        setPtToDate();
        setPtAmount();
        setPtPaidAmount();
        setPtPendingAmount();
        setPtPaidDate(getCurrentDate());
        setPtTransactionId();
      }
    } catch (e) {
      toast.error(e?.data?.error);
    }
  };

  const ptHandleCancel = () => {
    setPTButtonClicked(false);
    setTrainerName();
    setPtFromDate(getCurrentDate());
    setPtToDate();
    setPtDays();
    setPtAmount();
    setPtPaidAmount();
    setPtPendingAmount();
    setPtPaidDate(getCurrentDate());
    setPtTransactionId();
  };

  return (
    <>
      <Link style={{ color: "white" }} to={`/attendancesOfUser/${userId}`}>
        Go to Attendance
      </Link>
      <Modal
        show={deletePressed}
        onHide={(e) => setDeletePressed(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <strong>
              Member Details, Payments, Renewals and Attendances will be deleted
            </strong>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label style={{ color: "black" }}>
              Enter Member ID :
            </Form.Label>
            <Form.Control
              onChange={(e) => setDeleteUserId(e.target.value)}
              value={deleteUserId}
            ></Form.Control>
            <br />
            <Form.Label style={{ color: "black" }}>
              Enter Member Name :
            </Form.Label>
            <Form.Control
              onChange={(e) => setDeleteUserName(e.target.value)}
              value={deleteUserName}
            ></Form.Control>
            <br />
            <Form.Label style={{ color: "black" }}>
              Enter Your Pasword :
            </Form.Label>

            <InputGroup>
              <Form.Control
                type={pwdVisible ? "text" : "password"}
                onChange={(e) => setAdminPwd(e.target.value)}
                placeholder="enter password"
                value={adminPwd}
              ></Form.Control>
              <Button className="cyanThemeHeaderTop" onClick={setPwdVisibility}>
                {pwdVisible ? <Eye /> : <EyeSlash />}
              </Button>
            </InputGroup>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            onClick={confirmDelete}
          >
            Delete
          </Button>

          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <ReUsableModal
        title="PT Details"
        restrictTitle={
          ptExistingPendingAmount > 0 &&
          "You can not create another PT when there is PT Amount pending"
        }
        show={ptButtonClicked}
        size="md"
        onHide={ptHandleCancel}
        onSubmit={ptHandleSubmit}
        submit="Submit"
        cancel="Cancel"
        submitDisabled={
          ptExistingPendingAmount > 0 ||
          !ptDays ||
          !ptAmount ||
          !ptPaidAmount ||
          !trainerName
        }
        payload={[
          {
            fieldName: "Number of Days",
            fieldType: "number",
            placeHolder: "Enter number of days",
            fieldValue: ptDays,
            setMethod: setPtDays,
            minimumValue: 1,
            maximumValue: 366,
          },
          {
            fieldName: "From",
            fieldType: "date",
            fieldValue: ptFromDate,
            setMethod: setPtFromDate,
            minimumDate: "1970-01-01",
            maximumDate: "2045-01-01",
          },
          {
            fieldName: "PT Amount",
            fieldType: "number",
            placeHolder: "Enter PT amount",
            fieldValue: ptAmount,
            setMethod: setPtAmount,
            minimumValue: 1,
            maximumValue: 200000,
          },
          {
            fieldName: "Paid Amount",
            fieldType: "number",
            placeHolder: "Enter Paid amount",
            fieldValue: ptPaidAmount,
            setMethod: setPtPaidAmount,
            minimumValue: 1,
            maximumValue: ptAmount,
          },
          {
            fieldName: "Pending Amount",
            fieldType: "number",
            // placeHolder: "Enter Paid amount",
            fieldValue: ptPendingAmount,
            // setMethod: setPtPaidAmount,
            disabled: true,
          },
          {
            fieldName: "Payment Mode",
            fieldType: "text",
            fieldValue: ptTransactionId,
            setMethod: setPtTransactionId,
          },
          {
            fieldName: "Paid Date",
            fieldType: "date",
            fieldValue: ptPaidDate,
            setMethod: setPtPaidDate,
            minimumDate: "1970-01-01",
          },
          {
            fieldName: "Trainer Name",
            fieldType: "text",
            fieldValue: trainerName,
            setMethod: setTrainerName,
            notAllowedValues: ["", " ", null, undefined],
          },
        ]}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {editable ? (
            <UserEditScreen
              user={user}
              editStatus={handleEditStatus}
            ></UserEditScreen>
          ) : renewal ? (
            <RenewalScreen
              user={user}
              renewalStatus={handleRenewalStatus}
            ></RenewalScreen>
          ) : payment ? (
            <PaymentScreen
              user={user}
              paymentStatus={handlePaymentStatus}
            ></PaymentScreen>
          ) : ptPayment ? (
            <PtPaymentScreen
              user={{
                userId: user.userId,
                firstName: user.firstName,
                ptStartDate: ptDetails[0].ptStartDate,
                ptPendingAmount: ptDetails[0].pendingAmount,
              }}
              paymentStatus={handlePtPaymentStatus}
            ></PtPaymentScreen>
          ) : editExpiryDate ? (
            <UpdateExpiryScreen
              user={user}
              editExpiryDate={handleEditExpiryDateStatus}
            ></UpdateExpiryScreen>
          ) : (
            <>
              {userInfo?.isMobileScreen && (
                <Button
                  className={`${userInfo.themeColor}ThemeHeaderTop`}
                  onClick={handleBackButton}
                >
                  Back
                </Button>
              )}
              {isPhotoUploading ? (
                <Loader />
              ) : (
                <Row className="my-2">
                  <Col md={10}>
                    <Card style={{ borderStyle: "none" }}>
                      <ProfileImage
                        imageId={
                          userInfo.gymId?._id +
                          "" +
                          user?._id?.toString().substring(4)
                        }
                        setImageError={setImageError}
                      />
                      {imageError ? (
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Form.Group
                            as={Row}
                            className="my-1"
                            controlId="photo"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Form.Label column="true" sm="3">
                              Photo not available :
                            </Form.Label> */}
                            <Form.Control
                              style={{ width: "60%" }}
                              type="file"
                              onChange={handleFileChange}
                            />
                            <Col sm="6">
                              {fileSizeError && (
                                <label style={{ color: "red" }}>
                                  Image size should be less than 5 MB
                                </label>
                              )}
                              {fileInvalidError && (
                                <label style={{ color: "red" }}>
                                  Only jpeg, jpg, png, tiff and svg formats are
                                  supported
                                </label>
                              )}
                            </Col>
                          </Form.Group>
                          {file && (
                            <>
                              <Row column="true" sm="6">
                                <Col sm="4"></Col>
                                <Col sm="5">
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    onClick={handleSubmit}
                                  >
                                    {" "}
                                    {
                                      preferredLanguage[selectedLanguage]
                                        ?.commonTitles.submit
                                    }{" "}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      ) : (
                        <>
                          {" "}
                          <Form.Group
                            as={Row}
                            className="my-2"
                            controlId="photo"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Col sm="6">
                              <input type="file" onChange={handleFileChange} />
                              {fileSizeError && (
                                <label style={{ color: "red" }}>
                                  Image size should be less than 5 MB
                                </label>
                              )}
                              {fileInvalidError && (
                                <label style={{ color: "red" }}>
                                  Only jpeg, jpg, png, tiff and svg formats are
                                  supported
                                </label>
                              )}
                            </Col>
                          </Form.Group>
                          {file && (
                            <>
                              <Row column="true" sm="6">
                                <Col sm="4"></Col>
                                <Col sm="5">
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    onClick={handleSubmit}
                                  >
                                    {" "}
                                    {
                                      preferredLanguage[selectedLanguage]
                                        ?.commonTitles.submit
                                    }{" "}
                                    :{" "}
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
              )}
              <Row style={{ margin: "0.5%" }}>
                <Button
                  className=" marginPointOne sixteenWidth buttonLoveBlue numbersButtonAssociateProfile"
                  onClick={(e) => setRenewal(true)}
                  style={{ minHeight: "2.3rem" }}
                >
                  {preferredLanguage[selectedLanguage]?.commonTitles.renewal}{" "}
                </Button>

                {/* <Button
                  className=" marginPointOne sixteenWidth buttonTeal numbersButtonAssociateProfile"
                  onClick={(e) => setPayment(true)}
                  style={{ minHeight: "2.3rem" }}
                >
                  {preferredLanguage[selectedLanguage]?.commonTitles.payment}{" "}
                </Button> */}

                <Button
                  className=" marginPointOne sixteenWidth buttonTeal numbersButtonAssociateProfile"
                  onClick={(e) => setPTButtonClicked(true)}
                  style={{ minHeight: "2.3rem" }}
                >
                  PT
                </Button>
                {userInfo.role === "owner" ||
                userInfo.role === "Super Admin" ? (
                  <>
                    <Button
                      className=" marginPointOne sixteenWidth buttonOrange numbersButtonAssociateProfile"
                      onClick={(e) => setEditExpiryDate(true)}
                      style={{ minHeight: "2.3rem" }}
                    >
                      {
                        preferredLanguage[selectedLanguage]?.commonTitles
                          .changeValidity
                      }
                    </Button>{" "}
                  </>
                ) : (
                  ""
                )}
                {userInfo.role === "owner" ||
                userInfo.role === "Super Admin" ||
                userInfo.role === "Admin" ? (
                  <Button
                    className=" marginPointOne sixteenWidth buttonMaroon numbersButtonAssociateProfile"
                    onClick={(e) => setEditable(true)}
                    style={{ minHeight: "2.3rem" }}
                  >
                    {
                      preferredLanguage[selectedLanguage]?.commonTitles
                        .changeUserDetails
                    }
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  className=" marginPointOne sixteenWidth buttonGreen numbersButtonAssociateProfile"
                  onClick={generateBill}
                  style={{ minHeight: "2.3rem" }}
                >
                  {
                    preferredLanguage[selectedLanguage]?.commonTitles
                      .downloadBill
                  }
                </Button>
                {(userInfo.role === "owner" ||
                  userInfo.role === "Super Admin") && (
                  <Button
                    className=" marginPointOne sixteenWidth buttonReddish numbersButtonAssociateProfile"
                    onClick={(e) => setDeletePressed(true)}
                    style={{ minHeight: "2.3rem" }}
                  >
                    Delete Member
                  </Button>
                )}
              </Row>
              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .name
                            }{" "}
                          </Col>
                          <Col>
                            {" "}
                            {user?.firstName} {user?.lastName}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .id
                            }{" "}
                          </Col>
                          <Col> {user?._id?.toString().substring(4)}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .gender
                            }{" "}
                          </Col>
                          <Col> {user?.gender}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.package
                            }{" "}
                          </Col>
                          <Col> {user?.selectedPackage}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.subscriptionType
                            }{" "}
                          </Col>
                          <Col> {user?.subscriptionType}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.offerDetails
                            }{" "}
                          </Col>
                          <Col> {user?.notes}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.personalTrainerAvailed
                            }{" "}
                          </Col>
                          <Col> {user?.ptEnabled ? "Yes" : "No"}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.gymGoal
                            }{" "}
                          </Col>
                          <Col> {user?.gymGoal.join(" , ")}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.subscriptionAmount
                              }{" "}
                            </Col>
                            <Col> {user?.subscriptionAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.paidAmount
                              }{" "}
                            </Col>
                            <Col> {user?.paidAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row
                            style={{
                              color: user?.pendingAmount > 0 ? "red" : "",
                            }}
                          >
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.paymentsListPage.pending
                              }
                              {" Amount"}
                            </Col>
                            <Col>
                              {" "}
                              {user?.pendingAmount}{" "}
                              {user?.pendingAmount > 0 && (
                                <Link
                                  style={{
                                    color: "blue",
                                  }}
                                  onClick={(e) => setPayment(true)}
                                >
                                  <strong> Pay Now</strong>
                                </Link>
                              )}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.paidDate
                              }{" "}
                            </Col>
                            <Col>
                              {" "}
                              {user?.lastPaidDate
                                ? user?.lastPaidDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row
                            style={{
                              color: ptExistingPendingAmount > 0 ? "red" : "",
                            }}
                          >
                            <Col>PT Pending Amount</Col>
                            <Col>
                              {" "}
                              {ptExistingPendingAmount}{" "}
                              {ptExistingPendingAmount > 0 && (
                                <Link
                                  style={{
                                    color: "blue",
                                  }}
                                  onClick={(e) => setPtPayment(true)}
                                >
                                  <strong> Pay PT Now</strong>
                                </Link>
                              )}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>{" "}
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]
                                ?.registrationPage.dateOfBirth
                            }{" "}
                          </Col>
                          <Col> {user?.dateOfBirth?.split("T")[0]}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.registrationPage.joinedDate
                              }{" "}
                            </Col>
                            <Col>
                              {" "}
                              {user?.joinedDate
                                ? user?.joinedDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.renewedDate
                              }{" "}
                            </Col>
                            <Col>
                              {" "}
                              {user?.renewedDate
                                ? user?.renewedDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .validityTill
                            }{" "}
                          </Col>
                          <Col>
                            {" "}
                            {user?.subscriptionExpiryDate
                              ? user?.subscriptionExpiryDate?.split("T")[0]
                              : ""}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .aadharNumber
                            }{" "}
                          </Col>
                          <Col> {user?.aadharNumber}</Col>
                        </Row>
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .heigthInCm
                            }{" "}
                          </Col>
                          <Col> {user?.heigthInCm}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .weightInKg
                            }{" "}
                          </Col>
                          <Col> {user?.weightInKg}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .mobileNumber
                            }{" "}
                          </Col>
                          <Col> {user?.mobileNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .email
                            }{" "}
                          </Col>
                          <Col> {user?.email}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col>
                              {
                                preferredLanguage[selectedLanguage]
                                  ?.commonTitles.address
                              }{" "}
                            </Col>
                            <Col> {user?.address}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>Created Mode :</Col>
                          <Col>
                            {" "}
                            {user?.createdByUpload
                              ? "Bulk Upload"
                              : "Manual Registration"}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            {
                              preferredLanguage[selectedLanguage]?.commonTitles
                                .updatedBy
                            }{" "}
                          </Col>
                          <Col> {user?.updatedBy}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
              <hr
                style={{
                  border: "1px solid #ccc",
                  margin: "5px 0",
                }}
              />
              {paymentsLoading ? (
                <Loader />
              ) : (
                <Row className="my-2">
                  <Col>
                    <Card style={{ border: "none" }}>
                      <label
                        style={{
                          margin: "auto",
                          marginTop: "5px",
                          color: "black",
                        }}
                      >
                        {" "}
                        <strong>
                          {preferredLanguage[selectedLanguage]?.paymentsList}{" "}
                        </strong>
                      </label>
                      <div
                        style={{
                          display: "flex",
                          margin: "auto",
                          marginTop: "7px",
                          justifyContent: "center",
                          width: "100%",
                          justifyContent: "space-around",
                        }}
                      >
                        {paymentsLoading && renewalsLoading ? (
                          <Loader />
                        ) : paymentsError ? (
                          <Message>{paymentsError?.data?.error}</Message>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              margin: "auto",
                              justifyContent: "center",
                              width: "100%",

                              justifyContent: "space-around",
                            }}
                          >
                            <div className="tableContainer">
                              <Table
                                striped
                                bordered
                                hover
                                responsive="sm"
                                className="table-custom"
                              >
                                <thead
                                  className={`${userInfo.themeColor}ThemeThead`}
                                >
                                  <tr>
                                    <th>Subscription Amount</th>
                                    <th>Subscription Date</th>
                                    <th>Paid Amount</th>
                                    <th>Paid Date</th>
                                    <th>Payment Mode</th>
                                  </tr>
                                </thead>
                                {paymentsToList &&
                                paymentsToList?.length > 0 ? (
                                  <tbody
                                    className={`${userInfo.themeColor}ThemeTbody`}
                                  >
                                    {paymentsToList.map((user) => (
                                      <tr
                                        key={user.userId + "" + user.createdAt}
                                      >
                                        <td>{user.subscriptionAmount}</td>
                                        <td>
                                          {user.renewedDate?.split("T")[0]}
                                        </td>
                                        <td>{user.paidAmount}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                          {user.paidDate
                                            ? user.paidDate?.split("T")[0]
                                            : user.lastPaidDate?.split("T")[0]}
                                        </td>
                                        <td>{user.transactionId}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <Message>
                                    No Payments Available for the selected
                                    Period
                                  </Message>
                                )}
                              </Table>
                            </div>{" "}
                          </div>
                        )}
                      </div>
                    </Card>
                  </Col>
                </Row>
              )}

              <Row className="my-2">
                <Col>
                  <Card style={{ border: "none" }}>
                    <label
                      style={{
                        margin: "auto",
                        marginTop: "5px",
                        color: "black",
                      }}
                    >
                      {" "}
                      <strong>PT History</strong>
                    </label>
                    <div
                      style={{
                        display: "flex",
                        margin: "auto",
                        marginTop: "7px",
                        justifyContent: "center",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                    >
                      {ptLoading ? (
                        <Loader />
                      ) : ptError ? (
                        <Message>This member has no PT details</Message>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            margin: "auto",
                            justifyContent: "center",
                            width: "100%",

                            justifyContent: "space-around",
                          }}
                        >
                          <div className="tableContainer">
                            <Table
                              striped
                              bordered
                              hover
                              responsive="sm"
                              className="table-custom"
                            >
                              <thead
                                className={`${userInfo.themeColor}ThemeThead`}
                              >
                                <tr>
                                  <th>Days</th>
                                  <th>From</th>
                                  <th>To</th>
                                  <th>PT Amount</th>
                                  <th>Paid</th>
                                  <th>Paid Date</th>
                                  <th>Payment Mode</th>
                                  <th>Pending</th>
                                  <th>Trainer</th>
                                </tr>
                              </thead>
                              {allPtDetails && allPtDetails?.length > 0 ? (
                                <tbody
                                  className={`${userInfo.themeColor}ThemeTbody`}
                                >
                                  {allPtDetails.map((pt) => (
                                    <tr key={pt.userId + "" + pt.createdAt}>
                                      <td>{pt.ptDays}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {pt.ptStartDate?.split("T")[0]}
                                      </td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {pt.ptEndDate?.split("T")[0]}
                                      </td>
                                      <td>{pt.ptAmount}</td>
                                      <td>{pt.paidAmount}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {pt.paidDate?.split("T")[0]}
                                      </td>
                                      <td>{pt.transactionId}</td>
                                      <td>{pt.pendingAmount}</td>

                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {pt.trainerName}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <Message>
                                  No Payments Available for the selected Period
                                </Message>
                              )}
                            </Table>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserProfileScreen;
